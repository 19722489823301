

import { Grid, Button, IconButton, } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Icon } from '@iconify/react';
import SelectedRoomkey from '../component/AllcateRoom/SelectedRoomkey';
// import { getroomListAndStatus } from '../api/room.api';
import { ErrorToaster } from '../helper/toastHeleper';
import { guestRoomCheckout, guestRoomCheckoutRoomList } from '../api/guest.api';
import { setAddNewGuestModalOpen, setRoomDetailsOpen, setRoomListStatausToUpdate, setRoomListToUpdate } from '../store/Reducers/modal';
import ConfirmationModal from '../helper/alertModal/ConfirmationModal';
// import { error } from 'autoprefixer/lib/utils';

// Utility function for deep cloning objects
const deepClone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
};
const CheckoutForm = ({ setNewRoomModalOpen = () => { } }) => {
    const { roomBookingDto } = useSelector(({ modal }) => modal, shallowEqual);
    const [formData, setFormData] = useState(deepClone(Array.isArray(roomBookingDto.formData) ? roomBookingDto.formData : []));
    const dispatch = useDispatch();
    const [roomselectionDetails, setRoomselectionDetails] = useState({ totalBed: 0, extrabed: 0 });

    const optionss = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    };

    const handleSubmmit = async () => {
        const rooms = formData.map(data => ({
            room_id: data.room_id,
            assign_room_id: data.assign_room_id,
        }));
        const date = new Date();
        const dateString = date.toLocaleString('en-US', optionss);
        let formTosubmit = {
            guest_room_book_id: roomBookingDto.guest_room_book_id,
            guest_id: roomBookingDto.id,
            check_out_date: dateString,
            room: rooms
        }
        try {
            let res = await guestRoomCheckout(formTosubmit);
            if (res.status === 200) {
                dispatch(setNewRoomModalOpen(false))
                dispatch(setAddNewGuestModalOpen(false))
                dispatch(setRoomDetailsOpen(false))
                dispatch(setRoomListToUpdate(true))
                dispatch(setRoomListStatausToUpdate(true))
            }
        } catch (error) {
            ErrorToaster(error)
        }
    };



    const handleChange = (e, index) => {
        const { name, value, checked, type } = e.target;
        setFormData(prevFormData => {
            const newFormData = deepClone(prevFormData); // Deep clone previous form data
            if (!newFormData[index]) {
                newFormData[index] = {};
            }
            newFormData[index][name] = type === 'checkbox' ? checked : value;
            return newFormData;
        });
    };



    const fetchRoomListandStatus = useCallback(async (id) => {
        try {
            let res = await guestRoomCheckoutRoomList(id);
            if (res.status === 200) {
                const rooms = res.data;
                let countBed = 0
                let countExtrabed = 0
                rooms.map((data) => {
                    countBed = parseInt(countBed) + parseInt(data.bed)
                   return countExtrabed = parseInt(countExtrabed) + parseInt(data.extra_bed)
                });
                setRoomselectionDetails(prev => ({
                    ...prev,
                    extrabed: countExtrabed,
                    totalBed: countBed

                }));
                setFormData(rooms);

            } else throw res;

        } catch (error) {
            ErrorToaster(error);
        }
    }, [])






    // confirmation modal
    const [open, setOpen] = useState({})

    const handleClickOpen = () => {
         let { name="", surname="",office_purpose:{ gents = 0, ladies = 0, child = 0 }={}} = roomBookingDto
        const date = new Date();
        const dateString = date.toLocaleString('en-US', optionss);
        let totalGuest = (parseInt(gents || 0) + parseInt(ladies || 0) + parseInt(child || 0) + 1)
        setOpen({
            isopen: true,
            message: <div className=' text-center inter font-bold text-Text-Secondary'><table className='text-left '>
                <tr >
                    <td className='w-44 '>Guest Name</td>
                    <td className=''>{(name || "") + " " + (surname || "") + ""}</td>
                </tr>
                <tr>
                    <td>Total Guest</td>
                    <td>{isNaN(totalGuest) ? 1 : totalGuest}</td>
                </tr>
                <tr>
                    <td> Check-Out time is</td>
                    <td>{dateString}</td>
                </tr>
                <tr>
                    <td className='float-start'>Room No.</td>
                    <td>{formData.map((room, index) => <> <span className='text-nowrap'>B Block {room.room_no}</span>,{(index + 1) % 2 === 0 && <br />}</>)}</td>
                </tr>
                <tr>
                    <td className='float-start'>Total Bed:</td>
                    <td>{roomselectionDetails.totalBed}</td>
                </tr>
                <tr>
                    <td className='float-start'>Extra Bed:</td>
                    <td>{roomselectionDetails.extrabed}</td>
                </tr>
            </table>
            </div>,
            id: ""
        });
    };
    useEffect(() => {
        if (roomBookingDto.guest_room_book_id) {
            fetchRoomListandStatus({ guest_room_book_id: roomBookingDto.guest_room_book_id })
        }
    }, [roomBookingDto.guest_room_book_id])

    return (
        <React.Fragment>
            <Dialog
                fullWidth
                maxWidth={'lg'}
                open={true}
                onClose={() => dispatch(setNewRoomModalOpen(false))}
            >
                <DialogTitle className=' border-border  flex justify-between items-center !border-1 !p-1 !px-5 inter font-bold '  >Checkout From Room
                    <IconButton className='!text-Primary-Color inline float-end my-auto ' onClick={() => dispatch(setNewRoomModalOpen(false))}>
                        <Icon icon="solar:close-square-line-duotone" className='text-Text-Secondary' ></Icon>
                    </IconButton>
                </DialogTitle>

                <div className='mx-5 my-5'>
                    <Grid container spacing={{ xs: 1, md: 1 }} columns={12}>
                        {

                            formData.map((room, index) => <SelectedRoomkey key={room.id} handleChange={handleChange} handlecancel={null} index={index} roomDettails={room} isDisabled={true} />)

                        }
                    </Grid>
                </div>

                <DialogActions sx={{ padding: 0 }}>
                    <div className='bg-gray py-3  !px-5 inter flex w-full gap-3 h-full   justify-between '  >
                        <div className='flex gap-3'>
                            <span className='flex items-center inter font-semibold gap-2'>
                                <Icon icon="solar:exit-line-duotone" />
                                {formData.length}  Room Selected
                            </span>
                            {/* <span className='flex items-center gap-2'>
                                <Icon icon="solar:users-group-two-rounded-line-duotone" />
                                {roomselectionDetails.totalBed} + {roomselectionDetails.extrabed} Guest
                            </span> */}
                        </div>
                        <div>
                            <Button
                                type="submit"
                                variant="outlined"
                                onClick={() => dispatch(setNewRoomModalOpen(false))}
                                className=' !text-sm 2xl:text-base inter h-8 2xl:h-10 !rounded-lg'
                            >
                                <Icon icon="solar:arrow-left-line-duotone" className='text-xl mr-2' />
                                Guest Details
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={!roomBookingDto.id}
                                disableElevation={true}
                                onClick={() => handleClickOpen()}
                                className='  !text-sm 2xl:text-base inter !ml-4 h-8 2xl:h-10 !rounded-lg hover:!bg-hover-Button-BG disabled:!bg-disabled-Button-BG !bg-Primary-Color'
                            >
                                Check-Out
                                <Icon icon="solar:export-line-duotone" className='text-xl ml-2 rotate-90' />
                            </Button>
                        </div>
                    </div>
                    {/* <Button onClick={handleClose}>Close</Button> */}
                </DialogActions>

            </Dialog>


            <ConfirmationModal handleDelete={handleSubmmit} type='keyReturn' submitText='Check Out' setOpen={setOpen} open={open} />
        </React.Fragment>


    );
};

export default CheckoutForm





