import { FormControl, RadioGroup, Radio, FormControlLabel, Grid, Button, IconButton } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import InputField from '../component/CustomElement/InputField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Icon } from '@iconify/react';
import CustomActionDropdown from '../component/CustomElement/CustomActionDropdown';
import { setNewRoomNoFrom } from '../store/Reducers/modal';
import Amenties from '../component/formsection/Amenties';
import { getDivisionList, addDivision, deleteDivisionById } from '../api/division.api';
import { getCategoryList, addCategory, deleteCategoryById } from '../api/category.api';
import { addroom, getroomStatus, getroomById } from '../api/room.api';
import { ErrorToaster, SuccessToaster } from '../helper/toastHeleper';
import SelectDropDown from '../component/CustomElement/SelectDropDown';
import LoaderSpinner from '../helper/LoaderSpinner';

const NewRoomAdd = (props) => {
  let { isAddnewRoomOpen = false, setNewRoomModalOpen = () => { }, newRoomNoFrom = {}, setRoomListToUpdate = () => { } } = props;

  const [errors, setError] = useState({});
  const [formData, setFormData] = useState({ ...newRoomNoFrom, id: "", floor: "", room_name: "", status: 1, toilet_western_no: 1, toilet_indian_no: 1 });
  const [isLoading, setLoading] = useState(true);

  let dispatch = useDispatch();

  const validateData = () => {
    let requiredValue = ["division_id", "category_id", "room_name", "status"];
    let errorCount = 0;

    requiredValue.forEach((data) => {
      if (!formData[data] || formData[data] === "") {
        setError((prev) => ({ ...prev, [data]: "Field is required" }));
        errorCount++;
      } else {
        setError((prev) => ({ ...prev, [data]: null }));
      }
    });

    return errorCount;
  };

  const handleChange = (e) => {
    let { name, value, type, checked } = e.target;

    if (formData[name] || value) {
      setError((prev) => ({ ...prev, [name]: null }));
    }

    let newValue = value?.value ? value?.value : value;
    if (name==="room_name") {
      newValue = newValue.replace(/[^0-9-]/g, '');
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : newValue,
    }));
  };

  const handleSubmmit = async (e) => {
    // e.preventDefault();
    let vali = validateData(formData);
    if (vali) {
      return;
    }
    try {
      let formBody = { ...formData }
      if (!formBody.toilet_indian) {
        formBody.toilet_indian_no = 0
      } else {
        formBody.toilet_indian_no = formBody.toilet_indian_no || 1
      }
      if (!formBody.toilet_western) {
        formBody.toilet_western_no = 0
      } else {
        formBody.toilet_western_no = formBody.toilet_western_no || 1
      }
      const res = await addroom(formBody);
      if (res.status === 200) {
        SuccessToaster(res);
        dispatch(setNewRoomModalOpen(false));
        dispatch(setRoomListToUpdate(true));
        dispatch(setNewRoomNoFrom(formData));
      } else throw res;
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const [divisionList, setDivisionList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [roomStatusList, setRoomStatusList] = useState([]);

  const getDivisions = useCallback(async () => {
    try {
      const res = await getDivisionList();
      if (res.status === 200) {
        const ress = res.data.map((division) => ({
          value: division.id,
          label: division.name,
          ...division,
        }));
        setDivisionList(ress);
      } else {
        throw res;
      }
    } catch (error) {
      ErrorToaster(error);
    }
  }, []);

  const getRoomstatusList = useCallback(async () => {
    try {
      const res = await getroomStatus();
      if (res.status === 200) {
        const ress = [];
        Object.keys(res.data).map((key) => {
          return ress.push({ value: Number(key), label: res.data[key] });
        });
        setRoomStatusList(ress);
      } else throw res;
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setTimeout(() => setLoading(false), 200);
    }
  }, [formData.status]);

  const getCategory = useCallback(async () => {
    try {
      const res = await getCategoryList();
      if (res.status === 200) {
        const ress = res.data.map((cat) => ({
          value: cat.id,
          label: cat.name,
          ...cat,
        }));
        setCategoryList(ress);
      } else {
        throw res;
      }
    } catch (error) {
      ErrorToaster(error);
    }
  }, []);

  let fetchRoombyId = useCallback(async () => {
    if (isAddnewRoomOpen && isAddnewRoomOpen !== true) {
      try {
        let res = await getroomById(isAddnewRoomOpen);
        if (res.status === 200 && res.data) {
          setFormData(res.data);
          // SuccessToaster(res);
        } else throw res;
      } catch (error) {
        ErrorToaster(error);
      }
    }
  }, [isAddnewRoomOpen]);

  useEffect(() => {
    fetchRoombyId();
    getCategory();
    getDivisions();
    getRoomstatusList();
  }, []);

  const memoizedRoomStatusList = useMemo(() => {
    return roomStatusList.filter(({ value }) => {
      if (![2, 3].includes(formData.status) && [2, 3].includes(Number(value))) return false;
      return true;
    });
  }, [roomStatusList, formData]);
  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={'lg'}
        open={true}
        onClose={() => dispatch(setNewRoomModalOpen(false))}
      >
        <DialogTitle className=' border-border  flex items-center justify-between !border-1 !p-1 !px-5 inter !font-semibold '>
          Add New Room
          <IconButton className='!text-Primary-Color inline float-end my-auto ' onClick={() => dispatch(setNewRoomModalOpen(false))}>
            <Icon icon="solar:close-square-line-duotone" className='text-Text-Secondary'></Icon>
          </IconButton>
        </DialogTitle>
        {isLoading ? (
          <div className='relative h-80'>
            <LoaderSpinner />
          </div>
        ) : (
          <div >
            <div className='grid gap-4 mx-5 mt-4 -mb-2'>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={12}>
                <Grid item md={3}>
                  <CustomActionDropdown
                    name="division_id"
                    label="Division"
                    required={true}
                    onhandleAdd={addDivision}
                    onhandleDelete={deleteDivisionById}
                    options={divisionList}
                    value={formData.division_id}
                    onChange={handleChange}
                    className={`!pl-0 ${errors['division_id'] && "errorBoder"}`}
                    placeholder='Select Division'
                  />
                  {errors["division_id"] && <p className='error'>{errors['division_id']}</p>}
                </Grid>

                <Grid item md={3} lg={3}>
                  <CustomActionDropdown
                    name="category_id"
                    label="Category"
                    required={true}
                    options={categoryList}
                    onhandleAdd={addCategory}
                    onhandleDelete={deleteCategoryById}
                    value={formData.category_id}
                    onChange={handleChange}
                    placeholder='Select Category'
                    className={`!pl-0 ${errors['category_id'] && "errorBoder"}`}
                  />
                  {errors["category_id"] && <p className='error'>{errors['category_id']}</p>}
                </Grid>

                <Grid item md={3}>
                  <InputField
                    label='Room Number'
                    name='room_name'
                    // type="number"
                    className={`${errors['room_name'] && "errorBoder"}`}
                    required={true}
                    formData={formData.room_name}
                    onChange={handleChange}
                  />
                  {errors["room_name"] && <p className='error'>{errors['room_name']}</p>}
                </Grid>

                <Grid item md={3}>
                  <InputField
                    label={"Floor"}
                    name='floor'
                    type="number"
                    formData={formData.floor}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={{ xs: 2, md: 3 }} columns={12}>
                <Grid item md={3}>
                  <InputField
                    label={'Bed'}
                    name='bed'
                    type="number"
                    formData={formData.bed}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={3}>
                  <InputField
                    label={"Extra Bed"}
                    name='extra_bed'
                    type="number"
                    formData={formData.extra_bed}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item md={3} className=' flex !items-start !flex-col '>
                  <label className='text-xs font-semibold text-Text-Secondary  ' htmlFor=''>Original Key</label>
                  <FormControl className='flex items-center' component="fieldset">
                    <div className='h-10 flex items-center'>
                      <RadioGroup
                        aria-label="original_key"
                        name="original_key"
                        className='mt-1 2xl:mt-2'
                        value={formData.original_key}
                        onChange={handleChange}
                        row
                      >
                        <FormControlLabel value="1" control={<Radio size="small" />} label="Yes" />
                        <FormControlLabel value="0" control={<Radio size="small" />} label="No" />
                      </RadioGroup>
                    </div>
                  </FormControl>
                </Grid>

                <Grid item md={3} className=' flex !items-start !flex-col '>
                  <label className='text-xs font-semibold text-Text-Secondary  ' htmlFor=''>Duplicate Key</label>
                  <FormControl className='flex items-center' component="fieldset">
                    <div className='h-10 flex items-center'>
                      <RadioGroup
                        aria-label="duplicate_key"
                        name="duplicate_key"
                        value={formData.duplicate_key}
                        onChange={handleChange}
                        row
                      >
                        <FormControlLabel value="1" control={<Radio size="small" />} label="Yes" />
                        <FormControlLabel value="0" control={<Radio size="small" />} label="No" />
                      </RadioGroup>
                    </div>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={{ xs: 2, md: 3 }} columns={12}>
                <Grid item md={3} className=' flex !items-start !flex-col '>
                  <label className='text-xs font-semibold text-Text-Secondary  ' htmlFor=''>Extra Key</label>
                  <FormControl className='flex items-center' component="fieldset">
                    <div className='h-10 flex items-center'>
                      <RadioGroup
                        aria-label="original_key"
                        name="extra_key"
                        value={formData.extra_key}
                        onChange={handleChange}
                        row
                      >
                        <FormControlLabel value="1" control={<Radio size="small" />} label="Yes" />
                        <FormControlLabel value="0" control={<Radio size="small" />} label="No" />
                      </RadioGroup>
                    </div>
                  </FormControl>
                </Grid>

                <Grid item md={3}>
                  <FormControl sx={{ minWidth: 1 }}>
                    <SelectDropDown
                      name="status"
                      label="Room Status"
                      required={true}
                      className={`${errors['status'] && "errorBoder"}`}
                      disabled={[2, 3].includes(formData.status)}
                      options={memoizedRoomStatusList}
                      value={memoizedRoomStatusList.find((x) => parseInt(x.value) === parseInt(formData.status)) || {}}
                      onChange={handleChange}
                      placeholder='Select Room'
                    />
                    {errors["status"] && <p className='error'>{errors['status']}</p>}
                    <label className='text-sm font-semibold text-Text-Secondary mb-1 2xl:mb-2 ' htmlFor=''></label>
                  </FormControl>
                </Grid>

                <Grid item md={3}>
                  <InputField
                    name="remark"
                    formData={formData.remark}
                    onChange={handleChange}
                    label={"Remark"}
                  />
                </Grid>
              </Grid>
            </div>
            <Amenties formData={formData} handleChange={handleChange} />
            <DialogActions sx={{ padding: 0 }}>
              <div className='bg-gray py-3  !px-5 inter flex w-full gap-4 h-full   justify-end '>
                <Button
                  type="button"
                  onClick={() => dispatch(setNewRoomModalOpen(false))}
                  className=' !text-sm 2xl:text-base inter !p-2 h-8 2xl:h-10 !rounded-lg !text-Text-Secondary !bg-transparent'
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  onClick={handleSubmmit}
                  disableElevation={true}
                  className=' !text-sm 2xl:text-base inter h-8 2xl:h-10 !rounded-lg hover:!bg-hover-Button-BG disabled:!bg-disabled-Button-BG !bg-Primary-Color'
                >
                  Submit
                </Button>
              </div>
            </DialogActions>
          </div>
        )}
      </Dialog>
    </React.Fragment>
  );
};

export default NewRoomAdd;
