import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import InputField from '../component/CustomElement/InputField';
import PasswordField from '../component/CustomElement/PasswordField';
import { forgotPassword, resetPassword } from '../api/user.api';
import { ErrorToaster, SuccessToaster } from '../helper/toastHeleper';

export default function ForgotPassword() {
    const navigate = useNavigate();
    const [isResending, setIsResending] = useState(false);
    const [formData, setFormData] = useState({});
    const [isOtpSend, setOtpSend] = useState(false);
    const [otpResentTime, setOtpResentTime] = useState(120);

    const [Error, setError] = useState({});

    useEffect(() => {
        let interval;
        if (isOtpSend) {
            interval = setInterval(() => {
                setOtpResentTime((prev) => (prev > 0 ? prev - 1 : 0));
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isOtpSend]);

    const validateData = () => {
        let requiredValue = ["email"];
        let errorCount = 0;

        requiredValue.forEach((data) => {
            if (!formData[data] || formData[data] === "") {
                setError((prev) => ({ ...prev, [data]: "Field is required" }));
                errorCount++;
            } else {
                setError((prev) => ({ ...prev, [data]: null }));
            }
        });
        if (formData["email"] && !/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(formData["email"])) {
            setError((prev) => ({ ...prev, email: "Enter valid Email Address" }));
            errorCount++;
        }

        return errorCount;
    };

    const handleChange = (e) => {
        let { name, value } = e.target;
        if (formData[name]) {
            setError((prev) => ({ ...prev, [name]: null }));
        }
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmitMail = async () => {
        try {
            setIsResending(true);
            let { email } = formData;
            let res = await forgotPassword({ email });
            if (res.status === 200) {
                SuccessToaster({ massage: "OTP sent to email successfully." });
                setOtpSend(true);
                setOtpResentTime(120); // Reset timer to 120 seconds
                setIsResending(false);
            } else throw res;
        } catch (error) {
            ErrorToaster(error);
            setIsResending(false);
        }
    };

    const handleResetPassword = async () => {
        try {
            let { email, ...rest } = formData;
            setIsResending(true);
            let res = await resetPassword(rest);
            if (res.status === 200) {
                setOtpSend(false);
                navigate('/success', { state: { email } });
                setIsResending(false);
            } else throw res;
        } catch (error) {
            ErrorToaster(error);
            setIsResending(false);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateData()) {
            return;
        }
        isOtpSend ? handleResetPassword() : handleSubmitMail();
    };

    return (
        <div className='h-2/3 flex flex-col px-3'>
            <button onClick={() => navigate(-1)} className='text-sm text-gray-600 -translate-y-12 flex items-center'>
                <Icon className='inline' icon="solar:alt-arrow-left-line-duotone" /> Back
            </button>
            <div>
                <h1 className='text-xl font-bold my-2 text-Text-Primary'>Forgot Password</h1>
                <h5 className='text-sm text-Text-Secondary'>Campus Management System</h5>
            </div>
            <form className='min-w-64 my-8 2xl:min-w-80 max-w-80 2xl:max-w-80' onSubmit={handleSubmit}>
                {!isOtpSend ? (
                    <div className='my-4'>
                        <InputField
                            name="email"
                            type="text"
                            onChange={handleChange}
                            label='Email Id'
                            formData={formData.email}
                            required={true}
                        />
                        {Error.email && <p className='error absolute'>{Error.email}</p>}
                    </div>
                ) : (
                    <>
                        <div className='my-4'>
                            <InputField
                                label='Enter OTP'
                                name='code'
                                type='text'
                                placeholder="123456"
                                formData={formData.code}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='my-4'>
                            <PasswordField
                                name="password"
                                type="text"
                                onChange={setFormData}
                                label='Password'
                                formData={formData}
                            />
                        </div>
                    </>
                )}
                <Button
                    type="submit"
                    fullWidth
                    disabled={isResending}
                    variant="contained"
                    color="primary"
                    disableElevation={true}
                    className='h-8 2xl:h-10 !rounded-lg !mt-5 !text-sm 2xl:text-base  hover:!bg-hover-Button-BG disabled:!bg-disabled-Button-BG !bg-Primary-Color'
                >
                    {isOtpSend ? "Reset Password" : "Send OTP"}
                </Button>
                <span className='invisible'>
                    <PasswordField name="password" type="text" onChange={setFormData} formData={formData} />
                </span>
                <div className='flex gap-2 justify-center items-center'>
                    <span className='text-sm text-Text-Secondary'>Didn't receive OTP?</span>
                    <button
                        type='button'
                        disabled={!isOtpSend || isResending || otpResentTime > 0}
                        onClick={handleSubmitMail}
                        className='text-Primary-Color text-sm w-24'
                    >
                        Resend {otpResentTime > 0 && `(${Math.floor(otpResentTime / 60)}:${otpResentTime % 60})`}
                    </button>
                </div>
            </form>
        </div>
    );
}
