import { Button, Grid, Paper, Tab, Tabs } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import UserDettailForm from '../forms/UserDettailForm'
import { shallowEqual, useSelector,useDispatch } from 'react-redux';
import { changePassword, getUserById } from '../api/user.api';
import { ErrorToaster, SuccessToaster } from '../helper/toastHeleper';
import Header from '../component/Header';
import InputField from '../component/CustomElement/InputField';
import { logout } from '../store/Reducers/auth';
import ConfirmationModal from '../helper/alertModal/ConfirmationModal'
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const Profile = () => {
  let dispatch=useDispatch()
  const { userRoles } = useSelector((store) => store.auth, shallowEqual);
  const [userData, setUserData] = useState({})
  const [formData, setFormData] = useState({})
  const fetchUserToUpdate = useCallback(async () => {
    try {
      let res = await getUserById(userRoles.id);
      if (res.status === 200 && res.data) {
        setUserData(res.data)
      } else throw res;
    } catch (error) {
      ErrorToaster(error);
    }
  }, []);

  useEffect(() => {
    fetchUserToUpdate()
  }, [])

  const handleChange = (e) => {
    let { name, value } = e.target;
    // if (formData[name]) {
    //   // setError((prev) => ({ ...prev, [name]: null }));
    // }
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const [open, setOpen] = useState({})
  const handleClickOpen = () => {
    setOpen({
        isopen: true,
        message: <p className='text-center font-sans font-bold text-Text-Secondary'>
        After Change Password You Will Logout!.
        <br/>
        Do You Want Change Password? </p>,
        id: ""
    });
};

  let handleSubmit = async () => {
    try {
    let res = await changePassword({ ...formData, email: userData.email })
      if (res.status === 200) {
        dispatch(logout())
        localStorage.removeItem("permission-campus")
        localStorage.removeItem("permission-role")
        localStorage.removeItem("AuthToken")
        SuccessToaster(res)
      } else throw res
    } catch (error) {
      ErrorToaster(error)
    }
  }
  return (
    <div>
      <Header label='Profile'>

      </Header>
      <Grid container sx={{ gap: 2 }} component="main" className='my-5 z-50'>
        <Grid item xs={false} sm={12} md={2.8}>
          <UserDettailForm
            isEdit={true}
            isDisabled={true}
            formData={userData} />
        </Grid>
        <Grid item xs={false} sm={12} md={9}>
          <Paper elevation={0} className='border-border !border-1  mb-4'>
            <Tabs value={0} aria-label="basic tabs example">
              <Tab label={<div className='inter font-bold text-xs'>Change Password</div>} {...a11yProps(0)} />
            </Tabs>
          </Paper>
          <Paper elevation={0} key={module.module_name} className='border-border !border-1 pt-3 pb-4 px-2 mb-3 relative'>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={12}>
              <Grid item md={3}>
                <InputField
                  label="Old Password"
                  name='old_password'
                  formData={formData.old_password}
                  onChange={handleChange}
                  placeholder='123456789'
                />
              </Grid>
              <Grid item md={3}>
                <InputField
                  label="New Password"
                  name='new_password'
                  formData={formData.new_password}
                  onChange={handleChange}
                  placeholder='123456789'
                />
              </Grid>
              <Grid item md={3}>
                <InputField
                  label="Confirm Password"
                  name='confirm_password'
                  formData={formData.confirm_password}
                  onChange={handleChange}
                  placeholder='123456789'
                />
              </Grid>
              <Grid item md={3}>
                <label className={`text-xs block font-semibold text-Text-Secondary inter mb-1 2xl:mb-2 invisible`} >{"label"}</label>
                <Button variant="contained"
                  disableElevation={true}
                  color="primary"
                  fullWidth
                  type='buton'
                  onClick={handleClickOpen}
                  className='hover:!bg-hover-Button-BG disabled:!bg-disabled-Button-BG !bg-Primary-Color !hifull !rounded-lg'>
                  Reset Password
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <ConfirmationModal handleDelete={handleSubmit} type='logout' submitText='Confirm' setOpen={setOpen} open={open} />

    </div>
  )
}

export default Profile
