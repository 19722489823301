import React, { forwardRef } from 'react'
import SelectDropDown from '../../component/CustomElement/SelectDropDown'
import InputField from '../../component/CustomElement/InputField'
import { Grid } from '@mui/material'
import UploadedFile from '../UploadedFile'
import { deleteDocumentByIdAndTableType } from '../../api/guest.api'
import { ErrorToaster, SuccessToaster } from '../../helper/toastHeleper'
const OtherMemberDettails = ({ formData = {}, handleChange = () => { }, data, index, handleDeleteMember = () => { }, editMode = true},ref) => {

  let options = {
    1: "Aadhaar Card",
    2: "PAN Card",
    3: "Driving Licence",
    4: "Passport",
    5: "Voter Identity Card"
  }
  let placeholder = {
    1: "1234 5678 9101",
    2: "ABCDE1234F",
    3: "GJ1234567890123",
    4: "A1234567",
    5: "DL/01/001/000000"
  }


  const handleDeleteRow = async (index, e, guest_members, id) => {
    const confirm = await ref.current.openModal(
      <p className='text-center font-sans font-bold text-Text-Secondary'>
        <div className='text-Error-Color'> Delete Row ? </div>
        Deleting this Row is permanent and<br />
        cannot be recovered. All associated data will be lost.
      </p>, "id");
    if (!confirm) return
    if (id) {
      try {
        let body = { id, table_name: "guest_member" }
        let res = await deleteDocumentByIdAndTableType(body)
        if (res.status === 200) {
          handleDeleteMember(index, e, guest_members)
          SuccessToaster(res)
        }
      } catch (error) {
        ErrorToaster(error)
      }
    } else if (!id) {
      handleDeleteMember(index, e, guest_members)
    }
  }
  // const handleDeleteFile = async (index, e, name, id, table_name) => {
  //   const confirm = await ref.current.openModal(
  //     <p className='text-center font-sans font-bold text-Text-Secondary'>
  //       <div className='text-Error-Color'> Delete File ? </div>
  //       Deleting this File is permanent and<br />
  //       cannot be recovered. All associated data will be lost.
  //     </p>, "id");
  //   if (!confirm) return
  //   handleChange(index, e, name)
  // }
  const handleDeleteMemberFile = async (index, e, name, id) => {
    const confirm = await ref.current.openModal(
      <p className='text-center font-sans font-bold text-Text-Secondary'>
        <div className='text-Error-Color'> Delete File ? </div>
        Deleting this File is permanent and<br />
        cannot be recovered. All associated data will be lost.
      </p>, "id");
    if (!confirm) return
    console.log(formData.document_attach)
    if (!Array.isArray(formData.document_attach)) return
    if (id) {
      try {
        let body = { id, table_name: "guest_member_doc" }
        let res = await deleteDocumentByIdAndTableType(body)
        if (res.status === 200) {
          let coppiesArray = [...formData.document_attach]
          coppiesArray.splice(index, 1)
          handleChange(index, { target: { value: coppiesArray, name: "document_attach" } })
          SuccessToaster(res)
        }
      } catch (error) {
        ErrorToaster(error)
      }
    } else if (!id) {
      console.log(id,index)
      let coppiesArray = [...formData.document_attach]
      coppiesArray.splice(index, 1)
      console.log(coppiesArray)
      handleChange(index, { target: { value: coppiesArray, name: "document_attach" } })
    }

  }

  return (
    <div key={data + index} className='grid gap-4 mx-5 my-2 pb-2'>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={12}>
        <Grid item md={3}>
          <div className=' mb-1 2xl:mb-2'>
            <label className={`!text-xs inter font-semibold text-Text-Secondary `} htmlFor='full_name'>Full Name ( Surname + Name)</label>
            <div className='float-end'>
              <button type='button' disabled={!editMode} onClick={(e) => handleDeleteRow(index, e, "guest_members", formData.id)} name="guest_members" className={`!text-xs cursor-pointer inter mx-2  !text-Error-Color `}>Remove</button>
            </div>
          </div>
          <InputField
            id="full_name"
            name='full_name'
            type="text"
            disabled={!editMode}
            placeholder=""
            formData={formData.full_name}
            onChange={(e) => handleChange(index, e)}
          />

        </Grid>
        <Grid item md={3}>
          <div className=' mb-1 2xl:mb-2'>
            <label className={`!text-xs inter font-semibold text-Text-Secondary`} htmlFor='membermobile_number'>Mobile Number</label>
            <div className='float-end  invisible'>
              <label htmlFor="panfile" className='!text-xs cursor-pointer inter mx-2 !text-Primary-Color '>+ UPLOAD</label>
            </div>
          </div>
          <InputField
            name='mobile_number'
            isSearChable={true}
            type="phone"
            disabled={!editMode}
            placeholder="9877832437"
            formData={formData.mobile_number}
            onChange={(e) => handleChange(index, e)}
          />
        </Grid>
        <Grid item md={3}>
          <div className=' mb-1 2xl:mb-2'>
            <label className={`!text-xs inter font-semibold text-Text-Secondary`} htmlFor='memberdocument'>Document</label>
          </div>
          <SelectDropDown
            name='document_type'
            id={`document_type${index}`}
            disabled={!editMode}
            options={Object.keys(options).map((key) => ({ label: options[key], value: key }))}
            value={{ label: options[formData.document_type], value: formData.document_type }}
            onChange={(e) => handleChange(index, e)}
            isClearable={false}
            placeholder='Select Document'
            className=''
          />
        </Grid>
        <Grid item md={3}>
          <div className=' mb-1 2xl:mb-2'>
            <label className={`!text-xs inter font-semibold text-Text-Secondary`} htmlFor='memberadharCardNo'>{options[formData.document_type]}</label>
            <div className='float-end mb-1 2xl:mb-2'>
              {Array.isArray(formData.document_attach) && formData.document_attach.length > 0 ? <>
                {/* <a  rel="noreferrer"  target='_blank' href={URL.createObjectURL(formData.document_attach)} className='!text-xs cursor-pointer inter mx-2 !text-Primary-Color ' >Download</a> */}
                {/* <button disabled={!editMode} type='button' onClick={(e) => handleDeleteFile(index, { target: { value: [], name: "document_attach" } })} className='!text-xs cursor-pointer inter mx-2 !text-Error-Color'   >Remove</button> */}
              </> :
                <label htmlFor={`document_attach${index}`} name="document_attach" className='!text-xs cursor-pointer inter mx-2 !text-Primary-Color'>+ UPLOAD</label>}
            </div>
          </div>
          <input
            id={`document_attach${index}`}
            disabled={!formData.document_type || !editMode}
            onChange={(e) => handleChange(index, e)}
            className="hidden"
            multiple
            type="file"
            accept=".pdf"
          />
          <InputField
            id='document_number'
            name='document_number'
            type="text"
            disabled={!editMode}
            placeholder={placeholder[formData.document_type]}
            formData={formData.document_number}
            onChange={(e) => handleChange(index, e)}
          />
          {Array.isArray(formData.document_attach) &&
            formData.document_attach.map((data, index) => (
              <UploadedFile fileInfo={data.attachment} handleDeleteMember={handleDeleteMemberFile} fileId={data.id} index={index} name="document_attach" />
            ))}
        </Grid>
      </Grid>
    </div>
  )
}

export default forwardRef(OtherMemberDettails)