import { ErrorToaster } from "../toastHeleper";


export class validateData {
  constructor(formData) {
    this.errorCount = 0
    this.formData = formData
    this.requiredFields = [];

  }

  setRequiredFields = (fields) => {
    this.requiredFields = fields;
  };
  
  setValidation = (formData) => {
    this.formData = flattenObject(formData)
  }
  validate = () => {

    
    this.errorCount = 0;
    const newErrors = {};
    this.requiredFields.forEach((field) => {
      if (!this.formData[field]) {
        newErrors[field] = 'Field is required';
        this.errorCount++;
      }
    });

    if (this.formData.mobile_number && this.formData.mobile_number.length<10) {
      newErrors.mobile_number = 'Enter valid Phone Number';
      this.errorCount++;
    }
    if (this.formData.campus_contact_number && this.formData.campus_contact_number.length<10) {
      newErrors.campus_contact_number = 'Enter valid Phone Number';
      this.errorCount++;
    }
    if (this.formData.contact_person_mobile_number && this.formData.contact_person_mobile_number.length<10) {
      newErrors.contact_person_mobile_number = 'Enter valid Phone Number';
      this.errorCount++;
    }
    if (this.formData.pinCode && !/^\d{6}$/.test(this.formData.pinCode)) {
      newErrors.pinCode = 'Enter valid Pin Code';
      this.errorCount++;
    }
    if (this.formData.adharCardNo && !/^\d{4} \d{4} \d{4}$/.test(this.formData.adharCardNo)) {
      newErrors.adharCardNo = 'Enter valid Aadhar Number';
      this.errorCount++;
    }
    if (this.formData["email"] && !/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(this.formData["email"])) {
      newErrors.email =  "Enter valid Email Address";
      this.errorCount++;
    }
    return {
      Errors: newErrors,
      count: this.errorCount
    }
  }

  setErrorfalse = () => {
    this.errorCount = 0
  }


};


function flattenObject(ob) {
  let result = {};

  for (const i in ob) {
      if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i]) && ob[i] !== null) {
          const temp = flattenObject(ob[i]);
          for (const j in temp) {
              result[j] = temp[j];
          }
      } else {
          result[i] = ob[i];
      }
  }
  return result;
}

export const validateCheckInOutDates = (checkInDate, checkOutDate) => {
  if (!checkInDate || !checkOutDate) return;

  const checkInTime = new Date(checkInDate).getTime();
  const checkOutTime = new Date(checkOutDate).getTime();

  console.log(checkOutTime, checkInTime);

  if (checkInTime > checkOutTime) {
    ErrorToaster({ massage: "Check In date/time cannot be greater than the Check Out date/time. Please adjust the dates." });
    return false;
  }

  return true;
};