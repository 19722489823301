import React, { memo } from 'react'
import { FormControl, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import { Icon } from '@iconify/react';
const InputField = ({ max, name, type, onChange: setFormData = () => { }, label, formData = "", disabled = false, required = false, className = "", regex, placeholder = "",onSearchClick }) => {
    return (
        <FormControl fullWidth variant="outlined" >

            {label && <label className={`text-xs font-semibold text-Text-Secondary inter mb-1 2xl:mb-2 ${required && "required"}`} htmlFor={name}>{label}</label>}
            <OutlinedInput
                id={name}
                name={name}
                type={type}
                maxRows={max}
                variant="outlined"
                className={`h-10 !p-0 !rounded-lg !text-sm 2xl:text-base 2xl:h-10 ${className}`}
                value={formData===null?"":formData}
                disabled={disabled}
                placeholder={placeholder}
                endAdornment={
                    <InputAdornment position="end" className='mr-1'>
                        <IconButton onClick={onSearchClick} className={`hover:!bg-hover-Button-BG disabled:!bg-disabled-Button-BG !bg-Primary-Color !rounded-md w-8 h-8 !p-0  ${!(type === "phoneNumber" && onSearchClick) && "!hidden"}`}>
                            <Icon className='text-lg text-white' icon="solar:minimalistic-magnifer-outline" />
                        </IconButton>
                    </InputAdornment>
                }
                onChange={setFormData}
            />

        </FormControl>
    )
}

export default memo(InputField)