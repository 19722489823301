import React, { useCallback, useEffect, useState } from 'react'
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Grid } from '@mui/material';
import InputField from '../../component/CustomElement/InputField';
import CustomActionDropdown from '../CustomElement/CustomActionDropdown';
import { addPurpose, deletePurposeById, getPurposeList } from '../../api/purpose.api';
import { ErrorToaster } from '../../helper/toastHeleper';
const OfficePurpose = ({ formData = {}, handleChange, editMode = true,errors={} ,isCheckinDate}) => {
    // managiging Divison and Category 
    const [purposeList, setPurposeList] = useState([])


    const getPurpose = useCallback(async () => {
        try {
            const res = await getPurposeList();
            if (res.status === 200) {
                const ress = res.data.map(division => ({
                    value: division.id,
                    label: division.name,
                    ...division
                }));
                setPurposeList(ress);
            } else {
                throw res;
            }
        } catch (error) {
            ErrorToaster(error);
        }
    }, []);

    useEffect(() => {
        getPurpose()
    }, [getPurpose])
    return (
        <>

            <div className='bg-gray !py-3 border-1 border-border !px-5 inter font-bold  !text-sm '  >Office Purpose</div>

            <div className='grid gap-4 h- mx-5 my-4'>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={12}>
                    <Grid item md={3} className='flex !flex-col items-start'>
                        <label className={`!text-xs inter font-semibold text-Text-Secondary  mb-1 2xl:mb-2 `} htmlFor='check_in_date1'>Check-In-Date</label>
                        <MobileDateTimePicker
                            id='check_in_date1'
                            disablePast={true}
                            maxDate={dayjs(new Date(formData?.check_out_date))}
                            value={dayjs(new Date(formData?.check_in_date))}
                            name="check_in_date"
                            disabled={!editMode || isCheckinDate}
                            className='!mt-0 dateTimepicker  inter'
                            format='DD/MM/YYYY h:m A'
                            onAccept={(e) => handleChange({ target: { value: e["$d"], name: "check_in_date" } }, 'office_purpose')}
                            slotProps={{ textField: { size: 'small', fullWidth: true, } }}
                        />
                    </Grid>
                    <Grid item md={3} className='flex !flex-col items-start'>
                        <label className={`!text-xs inter font-semibold text-Text-Secondary mb-1 2xl:mb-2`} htmlFor='check_out_date2'>Check-Out-Date</label>
                        <MobileDateTimePicker
                            id="check_out_date2"
                            name='check_out_date'
                            minDateTime={dayjs(new Date(formData?.check_in_date))}
                            disabled={!editMode}
                            value={dayjs(new Date(formData?.check_out_date))}
                            className=' !mt-0 dateTimepicker border-gray'
                            format='DD/MM/YYYY h:m A'
                            onAccept={(e) => handleChange({ target: { value: e["$d"], name: "check_out_date" } }, 'office_purpose')}
                            slotProps={{ textField: { size: 'small', fullWidth: true } }}
                        />
                    </Grid>


                    <Grid item md={3}>


                        <CustomActionDropdown
                            name="purpose_id"
                            label="Purpose"
                            disabled={!editMode}
                            onhandleAdd={addPurpose}
                            onhandleDelete={deletePurposeById}
                            options={purposeList}
                            value={formData?.purpose_id}
                            onChange={(e) => handleChange(e, 'office_purpose')}
                            placeholder='Enter Purpose'
                            className=''
                        />

                    </Grid>
                    <Grid item md={3}>
                        <InputField
                            label={"Preferred"}
                            name='preferred'
                            type="text"
                            disabled={!editMode}
                            placeholder="First Floor"
                            formData={formData?.preferred}
                            onChange={(e) => handleChange(e, 'office_purpose')}
                        />
                    </Grid>

                </Grid>
            </div>

            <div className='grid gap-4 mx-5 my-4'>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={12}>
                    <Grid item md={3}>
                        <InputField
                            label='Vehicle No.'
                            name='vehicle_no'
                            type="text"
                            disabled={!editMode}
                            placeholder='GJ 13 GL 1234'
                            formData={formData?.vehicle_no}
                            onChange={(e) => handleChange(e, 'office_purpose')}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <InputField
                            label='Reference'
                            name='reference'
                            type="text"
                            disabled={!editMode}
                            placeholder='Enter Person Name'
                            required={true}
                            className={errors['reference'] && "errorBoder"}
                            formData={formData?.reference}
                            onChange={(e) => handleChange(e, 'office_purpose')}
                        />
                        {
                            errors["reference"] && <p className='error'>{errors['reference']}</p>
                        }

                    </Grid>
                    <Grid item md={3}>
                        <InputField
                            label='Reference Mo.'
                            name='reference_mobile'
                            type="text"
                            disabled={!editMode}
                            placeholder='1234567890'
                            formData={formData?.reference_mobile}
                            onChange={(e) => handleChange(e, 'office_purpose')}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <InputField
                            label='Suggest By'
                            name='suggest_by'
                            type="text"
                            disabled={!editMode}
                            placeholder='Enter Suggest Person'
                            required={true}
                            className={errors['suggest_by'] && "errorBoder"}
                            formData={formData?.suggest_by}
                            onChange={(e) => handleChange(e, 'office_purpose')}
                        />
                        {
                            errors["suggest_by"] && <p className='error'>{errors['suggest_by']}</p>
                        }

                    </Grid>

                </Grid>
            </div>
            <div className='grid gap-4 mx-5 my-4'>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={12}>
                    <Grid item md={3}>
                        <InputField
                            label='User Name'
                            name='user_name'
                            type="text"
                            required={true}
                            disabled={!editMode}
                            className={errors['user_name'] && "errorBoder"}
                            placeholder='Enter User Name'
                            formData={formData?.user_name}
                            onChange={(e) => handleChange(e, 'office_purpose')}
                        />
                        {
                            errors["user_name"] && <p className='error'>{errors['user_name']}</p>
                        }
                    </Grid>
                    <Grid item md={9}>
                        <InputField
                            label='Note'
                            name='note'
                            type="text"
                            disabled={!editMode}
                            placeholder='Enter Note'
                            formData={formData?.note}
                            onChange={(e) => handleChange(e, 'office_purpose')}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default OfficePurpose