import { Icon } from '@iconify/react';
import { Dialog, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';

const ProfilePicture = ({ handleChange, fileUrl = "", name, id, label = "Upload Image", customStyle ,isDisabled}) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [open, setOpen] = useState(false);
    useEffect(() => {

        if (/base64/.test(fileUrl)) {
            return
        }
        if (new RegExp(fileUrl).test("https://campusapi.digicampus.app/")) {
            setSelectedImage("");
        } else
            if (fileUrl) {
                setSelectedImage(fileUrl);
            }
    }, [fileUrl]);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            if (file.type === 'image/svg+xml') {
                reader.onloadend = () => {
                    const img = new Image();
                    img.src = reader.result;
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        canvas.width = img.width;
                        canvas.height = img.height;
                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0);
                        const dataUrl = canvas.toDataURL('image/png');
                        setSelectedImage(dataUrl);
                        handleChange({ target: { name: "avatar", files: dataUrl, type: 'file' } })
                    };
                };
                reader.readAsDataURL(file);
            } else {
                reader.onloadend = () => {
                    setSelectedImage(reader.result);
                    handleChange({ target: { name: "avatar", files: reader.result, type: 'file' } })
                };
                reader.readAsDataURL(file);
            }
        }
    };

    const handleImageRemove = () => {
        setSelectedImage(null);
        handleChange({ target: { name, files: [] } });
    };


    const handleViewFile = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className="flex flex-col items-center  mx-auto">
            <div className={`relative mx-auto ${customStyle} aspect-square rounded-full cursor-pointer group`}>
                {selectedImage ? (
                    <>
                        <img
                            onClick={handleViewFile}
                            src={selectedImage}
                            alt="Profile"
                            className="h-full w-full rounded-full object-cover"
                        />
                        {(handleChange && !isDisabled)&& <div className="absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100 bg-black bg-opacity-50 rounded-full">
                            <label htmlFor={id} className="cursor-pointer text-white mr-2">
                                <Icon className='text-3xl text-Primary-Color' icon="solar:camera-add-bold-duotone" />
                            </label>
                            <IconButton onClick={handleImageRemove} className="text-white">
                                <Icon icon="solar:trash-bin-trash-line-duotone" className='text-Error-Color' />
                            </IconButton>
                        </div>}
                    </>
                ) : (
                    (handleChange && !isDisabled)? <label htmlFor={id} className="h-full inter text-sm gap-2 cursor-pointer w-full rounded-full bg-Primary-Button-BG flex-col text-center flex items-center justify-center text-gray-500">
                        <Icon className='text-3xl text-Primary-Color' icon="solar:camera-add-bold-duotone" />
                        <span className='inter font-normal'> {label}</span>
                    </label> : " "

                )}
            </div>
            <div className=" hidden">
                <input
                    id={id}
                    name={name}
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}

                />
            </div>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <img
                    src={selectedImage}
                    alt="Profile"
                    className=" object-contain "
                />
            </Dialog>
        </div>
    );
};

export default ProfilePicture;
