import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Button, Dialog, DialogActions, Slide } from '@mui/material';
import deleteimg from "../../assets/alertBoxIcon/bin.gif";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmModal = forwardRef((props, ref) => {
  const [open, setOpen] = useState({ isopen: false, message: '', id: null });
  const [resolvePromise, setResolvePromise] = useState(null);

  useImperativeHandle(ref, () => ({
    openModal: (message, id) => {
      setOpen({ isopen: true, message, id });
      return new Promise((resolve) => {
        setResolvePromise(() => resolve);
      });
    }
  }));

  const handleClose = (result) => {
    setOpen({ isopen: false, message: '', id: null });
    if (resolvePromise) {
      resolvePromise(result);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open.isopen || false}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className='m-3'>
          <div className='m-2 ml-4 min-w-60'>
            <img src={deleteimg} className='mx-auto h-24' alt='delete.svg' />
          </div>
          <div className='mx-3 !font-sans text-sm'>{open.message}</div>
          <DialogActions className='m-2 flex !justify-center gap-2'>
            <Button
              variant='outlined'
              className='inter !font-bold h-7 !p-2 !rounded-lg !text-sm !normal-case !text-Text-Secondary'
              onClick={() => handleClose(false)}
            >
              Cancel
            </Button>
            <Button
              className='!bg-Error-Color inter !rounded-lg !font-bold h-7 !p-2 !text-sm !normal-case !text-white'
              onClick={() => handleClose(true)}
            >
{              props.submittext?props.submittext:"Delete"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </React.Fragment>
  );
});

export default ConfirmModal;
