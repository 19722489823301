

import { request } from "./httpservice"

// Base URL for API requests, retrieved from environment variables
let base_url = process.env.REACT_APP_BASE_API_URL

/* 
* Function to get a list of countries using a GET request
* @returns {Promise} - A promise that resolves to the list of countries
*/
export const getCategoryList = async () => {
    return request({
        url: `${base_url}/api/list-category`,
        method: 'GET',
    })
}

export const addCategory = async (body) => {
    return request({
        url: `${base_url}/api/add-update-category`,
        method: 'POST',
        data:body
    })
}

export const deleteCategoryById = async (id) => {
    return request({
        url: `${base_url}/api/delete-category/${id}`,
        method: 'GET',
    })
}
