
import { request } from "./httpservice"

// Base URL for API requests, retrieved from environment variables
let base_url = process.env.REACT_APP_BASE_API_URL

/* 
* Function to add a campus using a POST request
* @param {Object} body - The data to be sent in the request body
*/
export const getGuestList = async () => {
    return request({
        url: `${base_url}/api/list-guest`,
        method: 'GET',
    })
}
/* 
* Function to add a campus using a POST request
* @param {Object} body - The data to be sent in the request body
*/
export const addUpdateGuest = async (body) => {
    return request({
        url: `${base_url}/api/add-update-guest`,
        method: 'POST',
        data:body
    })
}
export const getGuestByPostMethod = async (body) => {
    return request({
        url: `${base_url}/api/search-guest`,
        method: 'POST',
        data:body
    })
}
// /* 
// * Function to add a campus using a POST request
// * @param {Object} body - The data to be sent in the request body
// */
export const getGuestById = async (id) => {
    return request({
        url: `${base_url}/api/get-id-wise-guest/${id}`,
        method: 'GET',
    })
}
// /* 
// * Function to add a campus using a POST request
// * @param {Object} body - The data to be sent in the request body
// */
export const deleteGuestById = async (id) => {
    return request({
        url: `${base_url}/api/delete-guest/${id}`,
        method: 'GET',
    })
}
// /* 
// * Function to add a campus using a POST request
// * @param {Object} body - The data to be sent in the request body
// */
export const guestRoomBooking = async (body) => {
    return request({
        url: `${base_url}/api/guest-room-booking`,
        method: 'POST',
        data:body
    })
}
export const guestRoomCheckout = async (body) => {
    return request({
        url: `${base_url}/api/guest-room-check-out`,
        method: 'POST',
        data:body
    })
}
export const guestRoomCheckoutRoomList = async (body) => {
    return request({
        url: `${base_url}/api/get-room-check-out-details`,
        method: 'POST',
        data:body
    })
}
export const getGuestHistoryList = async () => {
    return request({
        url: `${base_url}/api/get-dashboard-guest-history`,
        method: 'GET',
    })
}
export const getGuestFullHistoryListById = async (id) => {
    return request({
        url: `${base_url}/api/get-guest-booked-history/${id}`,
        method: 'GET',
    })
}
export const getGuestDettailByOfficePurposeAndUserID = async (body) => {
    return request({
        url: `${base_url}/api/get-guest-with-office-purpose-details`,
        method: 'POST',
        data:body,
    })
}
export const deleteDocumentByIdAndTableType = async (body) => {
    return request({
        url: `${base_url}/api/guest-delete-document`,
        method: 'POST',
        data:body,
    })
}
export const getfileview = async (body) => {
    return request({
        url: body,
        method: 'get',
    })
}
export const manyGuestDelete = async (body) => {
    return request({
        url: `${base_url}/api/multiple-record-delete`,
        method: 'POST',
        data:body
    })
}