import React, { memo, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Breadcrumbs, } from '@mui/material';
import { useSelector, shallowEqual } from 'react-redux';
import { setNewRoomModalOpen, setRoomDetailsOpen, setCheckInFromsOpen, setRoomListToUpdate, setAddNewGuestModalOpen, setCheckoutFormOpen } from '../store/Reducers/modal'
import NewRoomAddForm from '../forms/NewRoomAddForm';
import CheckInFrom from '../forms/CheckInFrom';
import GuestBookingEdit from '../forms/GuestBookingEdit';
import GuestBooking from '../forms/GuestBooking';
import CheckoutForm from '../forms/CheckoutForm';
const Header = ({ label = "", children, editRoomId = "" }) => {
    let location = useLocation()
    let shadow = useRef()
    let { isAddnewRoomOpen, isRoomDettailsopen, isCheckInFromsopen, newRoomNoFrom, isAddNewGuestModalOpen, isCheckoutForm } = useSelector((store) => {
        return {
            isAddnewRoomOpen: store.modal.isAddnewRoomOpen,
            newRoomNoFrom: store.modal.newRoomNoFrom,
            isRoomDettailsopen: store.modal.isRoomDetailsOpen,
            isCheckInFromsopen: store.modal.isCheckInFromsOpen,
            isAddNewGuestModalOpen: store.modal.isAddNewGuestModalOpen,
            isCheckoutForm: store.modal.isCheckoutForm,
        }
    }, shallowEqual);
    window.addEventListener('scroll', function () {
        if (window.scrollY >= 2) {
            if (shadow.current) {
                shadow.current.classList.add('!shadow-md')
            }
        } else {
            if (shadow.current) {
                shadow.current.classList.remove('!shadow-md')
            }
        }

    });

    return (
        <AppBar ref={shadow} elevation={0} position="fixed" className=' mb-2 pl-24 pr-6  !bg-white' >
            <Toolbar className='flex justify-between items-center gap-2 !p-0'>
                <div>
                    <label className='text-Text-Primar font-bold text-xl 2xl:text-2xl'>{label}</label>
                    <Breadcrumbs aria-label="breadcrumb" className='text-Text-Disable  2xl:text-sm'>

                        {
                            location.pathname.split('/').map((path, index) => {
                             
                                if (index === 0) {
                                    return <Link to="#"><p className='text-xs capitalize'>Dashboard</p></Link>
                                } if ("dashboard" == path) {
                                    return
                                }
                                let paths = location.pathname.split('/').slice(1, index + 1).join('/')
                                return (<Link key={paths + index} className='text-xs' to={"/" + paths}><p className='capitalize'>{path}</p></Link>)

                            })
                        }
                    </Breadcrumbs>
                </div>

                {children}

                {
                    isAddnewRoomOpen &&
                    <NewRoomAddForm
                        setRoomListToUpdate={setRoomListToUpdate}
                        editRoomId={editRoomId}
                        isAddnewRoomOpen={isAddnewRoomOpen}
                        setNewRoomModalOpen={setNewRoomModalOpen}
                        newRoomNoFrom={newRoomNoFrom}
                    />
                }

                {
                    isRoomDettailsopen &&
                    <GuestBookingEdit
                        isAddnewRoomOpen={isRoomDettailsopen}
                        setNewRoomModalOpen={setRoomDetailsOpen}
                    />
                }
                {
                    isAddNewGuestModalOpen &&
                    <GuestBooking
                        setNewRoomModalOpen={setAddNewGuestModalOpen}
                    />
                }

                {
                    isCheckInFromsopen &&
                    <CheckInFrom
                        setNewRoomModalOpen={setCheckInFromsOpen}
                    />
                }

                {
                    isCheckoutForm &&
                    <CheckoutForm
                        setNewRoomModalOpen={setCheckoutFormOpen}
                    />
                }
            </Toolbar>
        </AppBar>
    );
};


export default memo(Header);
