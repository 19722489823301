import { combineReducers } from 'redux';
import authReducer from './Reducers/auth'; // Assuming `authSlice` is a reducer, naming it as `authReducer` for clarity
import modalReducer from './Reducers/modal'; // Assuming `modalSlice` is a reducer, naming it as `modalReducer` for clarity
import tableReducer from './Reducers/table'; // Assuming `modalSlice` is a reducer, naming it as `modalReducer` for clarity



const rootReducer = combineReducers({
  auth: authReducer,
  modal: modalReducer, // Renamed `modalSlice` to `modalReducer` and fixed the key name
  table: tableReducer, // Renamed `modalSlice` to `modalReducer` and fixed the key name
});

export default rootReducer;
