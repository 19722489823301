import { FormControlLabel, Grid, Button, IconButton, } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import InputField from '../component/CustomElement/InputField'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Icon } from '@iconify/react';
import { validateCheckInOutDates, validateData } from '../helper/formhelper';
import { PersonalDetails, AddressDetail, OfficePurpose, DocumentDettails, OtherMemberDettails } from '../component/formsection/index'
import UploadedFile from '../component/UploadedFile';
import CusomCheckbox from '../component/CustomElement/CustomCheckbox';
import { setCheckInFromsOpen, setRevisiteMobileNoFetch, setRoomBookingDto, setRoomDetailsOpen, setRoomListStatausToUpdate, setRoomListToUpdate } from '../store/Reducers/modal';
import { addUpdateGuest, deleteDocumentByIdAndTableType, getGuestDettailByOfficePurposeAndUserID } from '../api/guest.api';
import { ErrorToaster, SuccessToaster } from '../helper/toastHeleper';
import dayjs from 'dayjs';
import ConfirmModal from '../helper/alertModal/ConfirmModal';
import GentsLadies from '../component/formsection/GentsLadies';
import { fileToDataUrlConvert } from '../helper/fileUpload';
const GuestBooking = () => {
  const [errors, setErrors] = useState({});
  // managing form modal
  const alertModalRef = useRef();
  const { isRoomDetailsOpen } = useSelector(({ modal }) => modal, shallowEqual)
  const [isSubmitting, setSubmitting] = useState(false);
  const oupload = useRef(null);
  const [formData, setFormData] = useState({
    mobile_number: '',
    country_code: "+91",
    whatsappNumber: '',
    is_whatsapp_number: false,
    surname: '',
    name: '',
    father_husband_name: '',
    mobile_number2: '',
    house_building_apartment: '',
    street_road_lane: '',
    pincode: '',
    country_id: 101,
    state_id: '',
    city_id: '',
    village_town: '',
    native_place: '',
    aadhar_card_attach: [],
    aadhar_card_no: '',
    pan_card_attach: [],
    pan_card_no: "",
    passport_attach: [],
    passport_no: "",
    driving_license_attach: [],
    driving_license_no: "",
    is_group_booking: "",
    group_booking_attachment: [], // if group booking enable this value goes other wise 
    guest_members: [{ full_name: '', mobile_number: '', document_type: '1', document_number: "", document_attach: "" }],// if group booking disabe 
    gents: "",
    ladies: "",
    child: "",
    office_purpose: {
      check_in_date: new Date(Date.now() + (60000 * 5)).toISOString(),
      check_out_date: dayjs(new Date()).add(7, 'day').toISOString(),
      purpose_id: "",
      preferred: "",
      gents: "",
      ladies: "",
      child: "",
      vehicle_no: "",
      reference: "",
      reference_mobile: "",
      suggest_by: "",
      user_name: "",
      note: ""
    }
  });

  const dispatch = useDispatch();
  const validation = new validateData();
  validation.setRequiredFields(["mobile_number", 'name', "reference", "user_name", 'suggest_by','village_town'])

  const handleChange = async (e, key2) => {
    const { name, value, type, checked, files } = e.target;
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: null, errorCount: 0 }));
    }
    if (name === "check_out_date") {
      const { check_in_date } = formData.office_purpose || {};
      if (!validateCheckInOutDates(check_in_date, value)) return;
    }
    if (name === "check_in_date") {
      const { check_out_date } = formData.office_purpose || {};
      if (!validateCheckInOutDates(value, check_out_date)) return;
    }

    let newValue = value?.value ? value.value : value
    if (name === 'mobile_number' ) {
      newValue = value.replace(/[^\d+]/g, '').slice(0, 10);
    }
    if ( name === 'whatsappNumber' || name === 'mobile_number2' || name === "reference_mobile") {
      if (value.startsWith("+")) {
        newValue = value.replace(/[^\d+]/g, '').slice(0, 15);
      } else {
        newValue = value.replace(/[^\d+]/g, '').slice(0, 10);
      }
    }
    if (name === 'country_code') {
      newValue = value.replace(/[^\d+]/g, '').slice(0, 5);
      if (!newValue.startsWith('+')) {
        newValue = '+' + value.replace(/[^\d+]/g, '').slice(0, 5);
      }
    }
    if (name === 'aadhar_card_no') {
      newValue = value.replace(/[^\d]/g, '').slice(0, 12).replace(/(\d{4})(?=\d)/g, '$1 ');
    }
    if (name === 'pincode') {
      newValue = value.replace(/[^\d+]/g, '').slice(0, 6)
    }

    if (key2) {
      setFormData((prev) => {
        let copyprev = JSON.parse(JSON.stringify(prev));;
        copyprev[key2][name] = type === 'checkbox' ? checked : type === 'file' ? files[0] : newValue;
        return copyprev;
      });
      return
    }
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : type === 'file' ? files[0] : newValue,
    }));
  };


  // managing for adding is_group_booking user other member
  const uploadfiles = async (e, document_type) => {
    let { name, files } = e.target
    if (files) {
      const filePromises = fileToDataUrlConvert(files,document_type,"attachment")

      try {
        const fileContents = await Promise.all(filePromises);
        setFormData({
          ...formData,
          [name]: [...formData[name], ...fileContents]
        });
      } catch (error) {
        console.error('Error uploading files:', error);
      }


    }
  };

  // managing for adding sinsgle user other member
  const addMember = () => {
    setFormData({
      ...formData,
      guest_members: [...formData.guest_members, { full_name: '', mobile_number: '', document_type: 1, document_number: "", document_attach: "" }]
    });
  };

  const handleMemberChange = async (index, e, key = "guest_members") => {
    const { name, value, files } = e.target;

    let newValue = value.value ? value.value : value
    if (files) {
      const filePromises = fileToDataUrlConvert(files,"","attachment")

      try {
        const fileContents = await Promise.all(filePromises);
        let updatedMembers = { ...formData }

        updatedMembers[key][index] = { ...updatedMembers[key][index], document_attach: fileContents }
        setFormData(updatedMembers)
        return
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    }

    let updatedMembers = { ...formData }
    if (name === 'mobile_number') {
      newValue = value.replace(/[^\d+]/g, '').slice(0, 10);
    }


    if (updatedMembers[key]?.[index]?.document_type === '1' && name === "document_number") {
      newValue = newValue.replace(/[^\d]/g, '').slice(0,).replace(/(\d{4})(?=\d)/g, '$1 ');
    }
    updatedMembers[key][index] = { ...updatedMembers[key][index], [name]: newValue }
    setFormData(updatedMembers)
  }

  const handleDeleteMember = async (index, e, name) => {
    let updatedMembers = { ...formData }
    updatedMembers[name]?.splice(index, 1)
    setFormData(updatedMembers)
  }

  const handleDeleteGroupFile = async (index, e, guest_members, id) => {
    const confirm = await alertModalRef.current.openModal(
      <p className='text-center font-sans font-bold text-Text-Secondary'>
        <div className='text-Error-Color'> Delete File ? </div>
        Deleting this Group File is permanent and<br />
        cannot be recovered. All associated data will be lost.
      </p>, "id");
    if (!confirm) return
    if (id) {
      try {
        let body = { id, table_name: "guest_group_doc" }
        let res = await deleteDocumentByIdAndTableType(body)
        if (res.status === 200) {
          handleDeleteMember(index, e, guest_members)
          SuccessToaster(res)
        }
      } catch (error) {
        ErrorToaster(error)
      }
    } else if (!id) {
      handleDeleteMember(index, e, guest_members)
    }
  }

  const handleGuestCheckIn = async (e) => {
    try {
      let { setValidation, validate } = validation
      setValidation(formData)
      let erro = validate()
      if (erro.count !== 0) {
        setErrors(erro.Errors)
        return
      }
      setSubmitting(true)
      let res = await addUpdateGuest(formData);
      if (res.status === 200) {
        dispatch(setCheckInFromsOpen(true))
        dispatch(setRoomBookingDto({ ...res.data, guest_room_book_id: isRoomDetailsOpen.guest_room_book_id, preCheck_in_date: isRoomDetailsOpen.check_in_date, isRoomEdit: true }))
        SuccessToaster(res);
      } else throw res;
    } catch (error) {
      ErrorToaster(error);
    }
    setSubmitting(false)
  };


  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      let { setValidation, validate } = validation
      setValidation(formData)
      let erro = validate()
      if (erro.count !== 0) {
        setErrors(erro.Errors)
        return
      }
      setSubmitting(true)
      let res = await addUpdateGuest(formData);
      if (res.status === 200) {
        SuccessToaster(res);
        dispatch(setRoomDetailsOpen(false))
        dispatch(setRoomListStatausToUpdate(true))
        dispatch(setRoomListToUpdate(true))
      } else throw res;
    } catch (error) {
      ErrorToaster(error);
    }
    setSubmitting(false)
  };
  const [editMode, setIsEditMode] = useState(true)
  let fetchGuestDettailByOfficePurposeAndUserID = async (body) => {
    try {
      let guestDettails = await getGuestDettailByOfficePurposeAndUserID(body)
      if (guestDettails.status === 200 && guestDettails.data) {
        setFormData(prev => ({ ...prev, ...guestDettails.data }))
        SuccessToaster({ massage: "Guest Details Fetched." })
      } else throw guestDettails

    } catch (error) {
      ErrorToaster({ massage: "Guest Not Found!" })
      setFormData((prev) => ({
        ...prev,
        id: "",
        is_whatsapp_number: false,
        surname: '',
        name: '',
        father_husband_name: '',
        mobile_number2: '',
        house_building_apartment: '',
        street_road_lane: '',
        pincode: '',
        country_id: 101,
        state_id: '',
        city_id: '',
        village_town: '',
        native_place: '',
        aadhar_card_attach: [],
        pan_card_attach: [],
        passport_attach: [],
        driving_license_attach: [],
        group_booking_attachment: [],
        guest_members: []
      })
      )
    }
  }

  useEffect(() => {
    return () => {
      // setIsEditMode(false)
      dispatch(setRoomBookingDto({}))
      dispatch(setRevisiteMobileNoFetch(""))
    }
  }, [dispatch])

  useEffect(() => {
    if (isRoomDetailsOpen && isRoomDetailsOpen !== "") {
      console.log(isRoomDetailsOpen.showButtonfalse)
      fetchGuestDettailByOfficePurposeAndUserID(isRoomDetailsOpen)
    }
  }, [])
  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={'lg'}
        open={true}
        onClose={() => dispatch(setRoomDetailsOpen(false))}
      >
        <DialogTitle className=' border-border  border-b-1 !p-1 flex items-center gap-2 justify-between  !px-5  '  ><p className='flex items-center inter  inter gap-2'>{isRoomDetailsOpen?.showButton!==false && "Edit"} Guest Details
          {/* <Tooltip title="Edit">
        <IconButton onClick={() => { setIsEditMode(!editMode) }}>
            <Icon className='text-base' icon="solar:pen-2-line-duotone" />
          </IconButton>
        </Tooltip> */}
        </p>
          <IconButton className='!text-Primary-Color inline  my-auto ' onClick={() => dispatch(setRoomDetailsOpen(false))}>
            <Icon icon="solar:close-square-line-duotone" className='text-Text-Secondary'></Icon>
          </IconButton>
        </DialogTitle>
        <form className='relative' onSubmit={handleSubmit}>
        <div className={isRoomDetailsOpen?.showButton===false && "disable_Edit"}>  </div>
          <div className='grid gap-4 mx-5 my-4'>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={12}>
              <Grid item md={3}>
                <div className='flex items-start flex-col'>
                  <label className={`text-xs font-semibold  text-Text-Secondary inter mb-1 2xl:mb-2 required`} htmlFor={"Mobile Number"}>Mobile Number</label>
                  <div className='flex  h-10  border-zero items-center'>
                    <input
                      className='w-14 p-2 outline-none rounded-md !border-none  inter !text-sm'
                      label=''
                      disabled={!editMode}
                      name='country_code'
                      placeholder="+91"
                      required={true}
                      value={formData.country_code}
                      onChange={handleChange}
                    /><span className='text-Text-Disable'>|</span>
                    <InputField
                      disabled={!editMode}
                      name='mobile_number'
                      type="phoneNumber"
                      placeholder="9877832437"
                      required={true}
                      className={`!pl-0 ${errors['mobile_number'] && "errorBoder"}`}
                      formData={formData.mobile_number}
                      onChange={handleChange}
                    />
                  </div>
                  {
                    errors["mobile_number"] && <p className='error'>{errors['mobile_number']}</p>
                  }
                </div>
                <div className='flex mb-2  items-center'>
                  <FormControlLabel
                    id='is_whatsapp_number'
                    name='is_whatsapp_number'
                    disabled={!editMode}
                    checked={Boolean(Boolean(formData.is_whatsapp_number))}
                    onChange={(e) => handleChange(e)}
                    className='!m-0'
                    control={<CusomCheckbox />} />
                  <label className='text-sm  text-Text-Secondary  text-nowrap ' htmlFor='is_whatsapp_number'>Same as Whatsapp Number</label>
                </div>

              </Grid>

              <Grid item md={3}>
                <InputField
                  label={"Whatsapp Number"}
                  name='whatsappNumber'
                  type="phone"
                  placeholder="9877832437"
                  disabled={formData.is_whatsapp_number || !editMode}
                  formData={formData.is_whatsapp_number ? (formData.country_code || "") + (formData.mobile_number || "") : formData.whatsappNumber}
                  onChange={handleChange}
                />
              </Grid>

            </Grid>
          </div>

          <PersonalDetails formData={formData} errors={errors} editMode={editMode} handleChange={handleChange} />
          <AddressDetail formData={formData} errors={errors} editMode={editMode} handleChange={handleChange} />
          <DocumentDettails ref={alertModalRef} formData={formData} errors={errors} handleMultifile={uploadfiles} editMode={editMode} handleDeleteMember={handleDeleteMember} handleChange={handleChange} header={true} />

          {/* other member dettails */}
          <>
            <div className='bg-gray flex  items-center justify-between  border-1 border-border !px-5 inter font-bold  !text-sm  '  >
              <div className=' inter  font-bold !text-sm uppercase '>Other Members Details</div>

              <GentsLadies formData={formData.office_purpose || {}} handleChange={handleChange} errors={errors} />
              <div className='flex items-center'>
                <FormControlLabel
                  id='is_group_booking'
                  name='is_group_booking'
                  checked={Boolean(formData?.is_group_booking)}
                  onChange={(e) => handleChange(e)}
                  className='!m-0'
                  control={<CusomCheckbox />} />
                <label className='text-sm font-semibold text-Text-Secondary  text-nowrap ' htmlFor=''>Group Booking</label>
              </div>
              <div className=' inter  font-bold !text-sm invisible '>Other Members Details</div>
            </div>

            {!formData.is_group_booking ?
              <>
                {
                  formData.guest_members?.map((data, index) => {
                    return <OtherMemberDettails key={index} ref={alertModalRef} handleChange={handleMemberChange} editMode={editMode} handleDeleteMember={handleDeleteMember} formData={data} index={index} />
                  })
                }
                <div className='grid pb-2 gap-4 mx-5 my-2'>
                  <Button
                    type='button'
                    variant="outlined"
                    disabled={!editMode}
                    sx={{ width: "fit-Content" }}
                    onClick={addMember}
                    className=' w-25 !text-sm 2xl:text-base inter !font-bold h-8 2xl:h-10 !rounded-lg !bg-Primary-Button-BG !border-none bottom-0'
                  >
                    <Icon icon="solar:user-plus-line-duotone" className='text-xl mr-2' />
                    Add Member
                  </Button>
                </div>
              </>
              :
              <>
                <div className=' mx-5 my-4'>
                  <Grid container gap={3}>
                    {
                      formData.group_booking_attachment.map((data, index) =>
                        <Grid key={String(data.attachment + index)} item md={2.8} className='flex  justify-between items-center gap-1'>
                          <UploadedFile fileInfo={data.attachment} handleDeleteMember={handleDeleteGroupFile} fileId={data.id} index={index} name="group_booking_attachment" />
                        </Grid>
                      )
                    }
                  </Grid>
                </div>
                <div className='grid pb-2 gap-4 mx-5 my-2'>
                  <Button
                    type='button'
                    variant="outlined"
                    disabled={!editMode}
                    sx={{ width: "fit-Content" }}
                    onClick={() => oupload.current.click()}
                    className=' w-25 !text-sm 2xl:text-base inter !font-bold h-8 2xl:h-10 !rounded-lg !bg-Primary-Button-BG !border-none bottom-0'
                  >
                    <Icon icon="solar:user-plus-line-duotone" className='text-xl mr-2' />
                    Upload File
                  </Button>
                  <input
                    ref={oupload}
                    type='file'
                    disabled={!editMode}
                    name='group_booking_attachment'
                    accept='.csv,.pdf'
                    multiple={true}
                    onChange={(e) => uploadfiles(e, 1)}
                    className='hidden'
                  />

                </div>
              </>

            }
          </>

          {/* Office Purpose */}
          <OfficePurpose formData={formData.office_purpose || {}} isCheckinDate={isRoomDetailsOpen.check_in_date} editMode={editMode} handleChange={handleChange} errors={errors} />
         {isRoomDetailsOpen?.showButton!==false && <DialogActions sx={{ padding: 0 }}>
            <div className='bg-gray py-3  !px-5 inter flex w-full gap-4 h-full   justify-end '  >
              <Button
                type="button"
                variant='text'
                onClick={() => dispatch(setRoomDetailsOpen(false))}
                className='  !text-sm 2xl:text-base inter !p-2 h-8 2xl:h-10 !rounded-lg !text-Text-Secondary'
              >
                cancel
              </Button>
              <Button
                type="submit"
                variant="outlined"
                disabled={isSubmitting || !editMode}
                className=' !text-sm 2xl:text-base inter  h-8 2xl:h-10 !rounded-lg'
              >
                {editMode ? "Update" : "Submit"}
              </Button>
              <Button
                type="button"
                onClick={handleGuestCheckIn}
                variant="contained"
                disableElevation={true}
                disabled={isSubmitting}

                className='  !text-sm 2xl:text-base inter h-8 2xl:h-10 !rounded-lg hover:!bg-hover-Button-BG disabled:!bg-disabled-Button-BG !bg-Primary-Color'
              >
                Room Update
                <Icon icon="solar:arrow-right-line-duotone" className='text-xl ml-2' />
              </Button>
            </div>
          </DialogActions>}
        </form>
      </Dialog>
      <ConfirmModal ref={alertModalRef} />
    </React.Fragment >


  );
};

// export default FormComponent;



export default GuestBooking