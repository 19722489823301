import { FormControlLabel, Paper } from '@mui/material'
import React from 'react'
import InputField from '../component/CustomElement/InputField'
import ProfilePicture from '../component/Profilepicure'
import CusomCheckbox from '../component/CustomElement/CustomCheckbox'

const UserDettailForm = ({ handleChange = () => { }, formData = {}, errors = {}, isEdit = false, isDisabled = false }) => {



  return (

    <Paper elevation={0} className='relative border-border  !border-1 p-3'>


      <ProfilePicture
        name="avatar"
        isDisabled={isDisabled}
        handleChange={handleChange}
        fileUrl={formData.avatar}
        customStyle='w-28 h-28 mb-4'
        id="userAvatar"
      />
     

     <span className='relative'>
     <div className={isDisabled && "disable_Edit"}>
     </div>
     <div className={`w-full`}>
          <div className='flex items-start flex-col '>
            <label className={`text-xs font-semibold  text-Text-Secondary inter mb-1 2xl:mb-2 required`} htmlFor={"Mobile Number"}>Mobile Number</label>
            <div className={`flex w-full h-10   border-zero items-center ${errors["mobile_number"] && 'errorBoder'}`}>
              <input
                className='w-14 p-2 outline-none rounded-md !border-none  inter !text-sm'
                label=''
                name='country_code'
                placeholder="+91"
                // disabled={isDisabled}
                required={true}
                value={formData.country_code}
                onChange={handleChange}
              /><span className='text-Text-Disable'>|</span>
              <InputField
                name='mobile_number'
                type="phoneNumber"
                // disabled={isDisabled}
                placeholder="9877832437"
                required={true}
                formData={formData.mobile_number}
                onChange={handleChange}
              />
            </div>

          </div>
          {
            errors.mobile_number && <p className='error absolute'>{errors.mobile_number}</p>
          }
          <div className='flex gap-1 mt-2 items-center'>
            <FormControlLabel
              id='is_whatsapp_number'
              name='is_whatsapp_number'
              // disabled={isDisabled}
              checked={Boolean(formData?.is_whatsapp_number)}
              onChange={(e) => handleChange(e)}
              className='!-m-0'
              control={<CusomCheckbox className='!px-0' />} />
            <label className='text-xs font-semibold text-Text-Secondary  text-nowrap ' htmlFor='sameWhattsNumber'>I use this number as Whatsapp Number</label>
          </div>
        </div>
        <div className='w-full my-4'>
          <InputField name="surname" type="text" onChange={handleChange} label='Surname' formData={formData.surname} />
        </div>
        <div className='w-full my-4'>
          <InputField name="name" type="text" onChange={handleChange} label='Name' className={errors['name'] && "errorBoder "} required={true} formData={formData.name} />
          {
            errors.name && <p className='error absolute'>{errors.name}</p>
          }
        </div>
        <div className='w-full my-4'>
          <InputField name="father_name" type="text" onChange={handleChange} label='Father/Husband Name' className={errors['father_name'] && "errorBoder "} required={true} formData={formData.father_name} />
          {
            errors.father_name && <p className='error absolute'>{errors.father_name}</p>
          }
        </div>
        <div className='w-full my-4'>
          <InputField name="email" type="text" onChange={handleChange} label='Email ID' formData={formData.email} />
          {
            errors.email && <p className='error absolute'>{errors.email}</p>
          }
        </div>
        <div className='w-full my-4'>
          {!isEdit && <InputField name="password" type="text" onChange={handleChange} label='Password' formData={formData.password} />}
        </div>
     </span>


    </Paper>
  )
}

export default UserDettailForm