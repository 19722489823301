import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import {newroomForm} from '../raw'
import storage from 'redux-persist/lib/storage';

const initialState = {
  isAddnewRoomOpen: false,
  isRoomDetailsOpen: false, // Corrected the typo in the state key
  isCheckInFromsOpen: false, // Corrected the typo in the state key
  isAddNewGuestModalOpen:false,
  country:[],
  newRoomNoFrom:newroomForm,
  isRoomlistToupdate:false,
  roomBookingDto:{},
  isCheckoutForm:false,
  revisiteMobileNoFetch:"",
  roomListStatausToUpdate:false,
  customActionToUpdate:false,

};

const modalPersistConfig = {
  key: 'modal', // Changed the key to 'modal' for modal persistence
  storage: storage,
  blacklist: ["customActionToUpdate","isCheckInFromsOpen","isRoomDetailsOpen","isAddnewRoomOpen","roomBookingDto","isRoomlistToupdate","isAddNewGuestModalOpen","isCheckoutForm"] // No need to blacklist anything if you want to persist all modal state
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setNewRoomModalOpen: (state, action) => { // Corrected the action creator name
      state.isAddnewRoomOpen = action.payload;
    },
    setRoomBookingDto: (state, action) => { // Corrected the action creator name
      state.roomBookingDto = action.payload;
    },
    setRoomListToUpdate: (state, action) => { // Corrected the action creator name
      state.isRoomlistToupdate = action.payload;
    },
    setNewRoomNoFrom: (state, action) => { 
     // Corrected the action creator name
      state.newRoomNoFrom = action.payload;
    },
    setAddNewGuestModalOpen: (state, action) => { 
     // Corrected the action creator name
      state.isAddNewGuestModalOpen = action.payload;
    },
    setRoomDetailsOpen: (state, action) => { // Corrected the action creator name
      state.isRoomDetailsOpen = action.payload;
    },
    setCheckInFromsOpen: (state, action) => { // Corrected the action creator name
      state.isCheckInFromsOpen = action.payload;
    },
    setCoutryList: (state, action) => { // Corrected the action creator name
      state.country = action.payload;
    },
    setCheckoutFormOpen: (state, action) => { // Corrected the action creator name
      state.isCheckoutForm = action.payload;
    },
    setRevisiteMobileNoFetch: (state, action) => { // Corrected the action creator name
      state.revisiteMobileNoFetch = action.payload;
    },
    setPurposeIdAndGuestIdCheckout: (state, action) => { // Corrected the action creator name
      state.revisiteMobileNoFetch = action.payload;
    },
    setRoomListStatausToUpdate: (state, action) => { // Corrected the action creator name
      state.roomListStatausToUpdate = action.payload;
    },
    setCustomActionsToUpdate: (state, action) => { // Corrected the action creator name
      state.customActionToUpdate = action.payload;
    },
  },
});


// Action creators are generated for each case reducer function
export const {setCustomActionsToUpdate,setPurposeIdAndGuestIdCheckout, setRoomListStatausToUpdate,setNewRoomModalOpen,setRoomDetailsOpen,setCheckInFromsOpen,setNewRoomNoFrom,setCoutryList,setRoomListToUpdate ,setAddNewGuestModalOpen,setRoomBookingDto,setCheckoutFormOpen,setRevisiteMobileNoFetch} = modalSlice.actions;

// Reducer
// export default modalSlice.reducer;
// Reducer
export default persistReducer(modalPersistConfig, modalSlice.reducer);
