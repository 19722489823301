

import { request } from "./httpservice"

// Base URL for API requests, retrieved from environment variables
let base_url = process.env.REACT_APP_BASE_API_URL

export const getDivisionList = async () => {
    return request({
        url: `${base_url}/api/list-division`,
        method: 'GET',
    })
}

export const addDivision = async (body) => {
    return request({
        url: `${base_url}/api/add-update-division`,
        method: 'POST',
        data:body
    })
}

export const deleteDivisionById = async (id) => {
    return request({
        url: `${base_url}/api/delete-division/${id}`,
        method: 'GET',
    })
}
export const getDivisionById = async (id) => {
    return request({
        url: `${base_url}/api/get-id-wise-division/${id}`,
        method: 'GET',
    })
}