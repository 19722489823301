import { Icon } from '@iconify/react/dist/iconify.js';
import { IconButton } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { ErrorToaster, SuccessToaster } from '../../helper/toastHeleper';
import AlertDialogSlide from '../../helper/alertModal/deleteModal';
import { setCustomActionsToUpdate } from '../../store/Reducers/modal';
import { useDispatch } from 'react-redux';

// delete edit add deopdown
const CustomActionDropdown = ({
    onChange: changeValue = () => { },
    value: prevalue = "",
    options: dffaultooptions = [],
    className = '',
    name = '',
    required = false,
    label,
    placeholder = '',
    onhandleDelete = () => { },
    onhandleAdd = () => { },
    disabled = false
}) => {
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [addFields, setAddField] = useState({ name: "" })
    const [options, setOption] = useState(dffaultooptions)
    const [alertOpen, setAlertOpen] = useState(false);
    // let defaultvalue = useMemo(() => options.find((x) => x.value === prevalue), [prevalue, options]) || {}
    const [value, setValue] = useState({});

    useEffect(() => {
        const defaultValue = dffaultooptions.find((x) => x.value === prevalue) || {};
        setValue(defaultValue);
    }, [prevalue, dffaultooptions]); let dispatch = useDispatch()
    const dropdownRef = useRef(null);

    useEffect(() => {
        // Function to handle click outside dropdown
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false);
            }
        }

        // Adding event listen
        document.addEventListener("mousedown", handleClickOutside);

        // Cleanup function to remove event listener
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    function toggleDropdown() {
        setOpen(!open);
    }

    function handleInputChange(event) {
        setSearchTerm(event.target.value);
    }

    const placeholderText = !value.label ? placeholder : value.label;

    let handleDelete = (id) => {
        onhandleDelete(id).then((res) => {
            if (res.status === 200) {
                SuccessToaster(res)
                let deleted = options.filter((option) => option.id !== id)
                setOption(deleted)
                setAddField({ name: "" })
                setOpen(false)
                dispatch(setCustomActionsToUpdate(true))
                setAlertOpen({})
            } else throw res
        }).catch((err) => {
            ErrorToaster(err)
        })
    }
    let handleEdit = async (value) => {
        setAddField(value)
    }
    let handleAdd = () => {
        onhandleAdd(addFields).then((res) => {
            if (res.status === 200 && res.data) {
                let newField = {
                    label: res.data.name,
                    value: res.data.id,
                    ...res.data
                }
                if (addFields.id) {
                    options.forEach((data, index) => {
                        if (data.id === addFields.id) {
                            return options[index] = newField
                        }
                        return options
                    })
                    if (value.id === addFields.id) {
                        setValue(newField)
                    }
                    setOption(options)
                } else {
                    setOption((prevOptions) => [...prevOptions, newField]);
                    setOpen(false)
                }
                dispatch(setCustomActionsToUpdate(true))
                SuccessToaster(res)
                setAddField({ name: "" })
            } else throw res
        }).catch((err) => {
            ErrorToaster(err)
        })
    }

    // Filtering options based on search term
    const filteredOptions = options.filter(option =>
        option.label?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        setOption(dffaultooptions)
    }, [dffaultooptions])

    // Handle delete campus

    const handleClickOpen = (id) => {
        let seleted = options.find((x) => x.value === id)
        setAlertOpen({
            isopen: true,
            message: <p className='text-center font-sans font-bold text-Text-Secondary'>
                <div className='text-Error-Color mb-2'> {seleted?.label}</div>
                Deleting {label} <br />
                and, cannot be recovered. All associated data will be lost.
            </p>, id
        });
    }
    return (
        <div ref={dropdownRef} className={`w-full flex flex-col relative ${className}`}>
            <AlertDialogSlide open={alertOpen} setOpen={setAlertOpen} handleDelete={handleDelete} />
            <label className={`text-xs font-semibold inter text-Text-Secondary mb-1 2xl:mb-2 ${required && "required"}`} htmlFor={"name"}>{label}</label>
            <span className='relative'>

                {!value.label ? <input
                    type="text"
                    autoFocus={false}
                    value={searchTerm}
                    disabled={disabled}
                    placeholder={placeholderText}
                    onChange={handleInputChange}
                    onClick={() => setOpen(true)}
                    className="focus:border-Primary-Color focus:border-2 border-1 border-field outline-none text-sm !rounded-lg h-10 w-full !flex items-center relative pl-3"
                /> :

                    <button type='button' disabled={disabled} onClick={toggleDropdown} className='focus:border-Primary-Color focus:border-2 border-1 border-field !rounded-lg h-10 w-full !flex items-center relative'>
                        <span className={`text-left dropbtn ml-3 w-full overflow-clip ${disabled && 'text-Text-Disable'}`}>{value.label}</span>
                        {value.label && !disabled && <div className='absolute right-5 top-1/2 -translate-y-1/2'>
                            <label htmlFor={name + "delete"} className='cursor-pointer'><Icon icon="system-uicons:cross" /></label>
                            <button id={name + "delete"} value={""} className='hidden' name={name} type='button' onClick={(e) => { changeValue({ target: { name, value: null } }); setValue({}); setSearchTerm("") }}>""</button>
                        </div>}
                    </button>

                }
                <Icon icon={`mingcute:${!open ? "down" : "up"}-line`} className='absolute text-field right-2 top-1/2 -translate-y-1/2 ' />
            </span>
            <div className={`dropdown-content w-full absolute ${open ? "!block" : "hidden"} rounded z-50 p-2 max-h-44`}>

                {filteredOptions.length > 0 ? (
                    filteredOptions.map((label) => (
                        <div className='flex justify-between hover:!bg-menu-bg rounded-md p-1 pl-2' key={label.id}>
                            <button
                                type='button'
                                name={name}
                                className='text-left !rounded-lg text-nowrap overflow-hidden text-ellipsis  text-black  w-full text-sm inter'
                                value={label.value}
                                onClick={(e) => {
                                    changeValue({ target: { name, value: label } });
                                    setValue(label)
                                    toggleDropdown();
                                }}>
                                {label.label}
                            </button>
                            <div className='!p-0 float-end text-nowrap'>
                                <IconButton className='!text-Primary-Color text-sm' onClick={() => handleClickOpen(label.id)}>
                                    <Icon icon="solar:trash-bin-trash-line-duotone" className='inline text-sm' />
                                </IconButton>
                                <IconButton className='!text-Primary-Color' onClick={() => handleEdit(label)}>
                                    <Icon icon="solar:pen-2-line-duotone" className='inline text-sm' />
                                </IconButton>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="text-sm text-gray-500 text-center">No options found.</div>
                )}
                <div className='flex bg-Primary-Button-BG  p-1 pl-2 rounded-md'>
                    <input
                        key={label}
                        type='text'
                        name={name}
                        className='text-left !rounded-lg  bg-transparent outline-none w-full text-xs inter'
                        value={addFields.name}
                        placeholder={`Add ${String(label).substring(0, 15)}`}
                        onChange={(e) => {
                            setAddField((prev) => ({ ...prev, name: e.target.value }))
                        }}>
                    </input>
                    <IconButton className='!text-Primary-Color ' disabled={addFields.name?.length <= 2} onClick={() => handleAdd()}>
                        {addFields.id ? <Icon icon="icon-park-outline:check-correct" className='inline text-base' /> : <Icon icon="solar:add-square-bold-duotone" className='inline text-base' />}
                    </IconButton>
                </div>
            </div>
        </div>
    );
};

export default CustomActionDropdown;
