import { request } from "./httpservice"

// Base URL for API requests, retrieved from environment variables
let base_url = process.env.REACT_APP_BASE_API_URL

/* 
* Function to log in a user using a POST request
* @param {Object} body - The data to be sent in the request body for login
* @returns {Promise} - A promise that resolves to the login response
*/
export const userlogin = async (body) => {
    return request({
        url: `${base_url}/api/login`,
        method: 'POST',
        data: body
    })
}

/* 
* Function to register a new user using a POST request
* @param {Object} body - The data to be sent in the request body for registration
* @returns {Promise} - A promise that resolves to the registration response
*/
export const userRegistration = async (body) => {
    return request({
        url: `${base_url}/api/registration`,
        method: 'POST',
        data: body
    })
}

/* 
* Function to get all users using a GET request
* @param {Object} body - Optional data to be sent in the request body
* @returns {Promise} - A promise that resolves to the list of all users
*/
export const getAllusers = async (body) => {
    return request({
        url: `${base_url}/api/get-all-users`,
        method: 'GET',
    })
}
/* 
* Function to get all users using a GET request
* @param {Object} body - Optional data to be sent in the request body
* @returns {Promise} - A promise that resolves to the list of all users
*/
export const getUserById = async (id) => {
    return request({
        url: `${base_url}/api/get-id-wise-user/${id}`,
        method: 'GET',
    })
}
export const deleteUserByid = async (id) => {
    return request({
        url: `${base_url}/api/delete-user/${id}`,
        method: 'GET',
    })
}
export const getRolePermissionJson = async (body) => {
    return request({
        url: `${base_url}/api/get-role-campus-wise-permissions`,
        method: 'POST',
        data: body
    })
}
export const forgotPassword = async (body) => {
    return request({
        url: `${base_url}/api/forgot-password`,
        method: 'POST',
        data: body
    })
}
export const resetPassword = async (body) => {
    return request({
        url: `${base_url}/api/password-reset`,
        method: 'POST',
        data: body
    })
}
export const changePassword = async (body) => {
    return request({
        url: `${base_url}/api/password-change`,
        method: 'POST',
        data: body
    })
}
