import React, { useState } from 'react'
import { FormControl, OutlinedInput, InputAdornment } from '@mui/material';
import { Icon } from '@iconify/react';
const PasswordField = ({  name, type,onChange:setFormData=()=>{}, label,formData={}}) => {
    const [showPassword, setShowPassword] = useState(false);


    const handleChange = (event) => {
        let { value = "", name } = event.target
        if (!showPassword && name === "password") {
            let value1 = String(value).slice(value.length - 1)
            value1 = value1 === '*' ? "" : value1
            let value2 = value1 === '' ? String(formData.password).slice(0, value.length) : String(formData.password).slice(0, value.length - 1)
            setFormData({ ...formData, [name]: value2 + value1 });
            return
        }
        setFormData({ ...formData, [name]: value });
    };

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    let { [name]: fieldValue = "" } = formData
   
    return (
        <FormControl fullWidth variant="outlined">
             { label && <label className='text-xs  inter font-semibold text-Text-Secondary mb-1 2xl:mb-2' htmlFor={name}>{label}</label>}
            {
                name === 'password' && <OutlinedInput
                    id={name}
                    name={name}
                    type={type}
                    value={!showPassword ? '*'.repeat(fieldValue.length) : fieldValue}
                    onChange={handleChange}
                    size='small'
                    className='h-10 !rounded-lg !text-sm 2xl:text-base 2xl:h-10'
                    endAdornment={
                        <InputAdornment position="end">
                            <span
                                className='cursor-pointer text-lg'
                                onClick={handleShowPassword}
                                edge="end"
                            >
                                {showPassword ? <Icon icon="solar:eye-bold" /> : <Icon icon="solar:eye-closed-bold" />}
                            </span>
                        </InputAdornment>
                    }
                />
            }
        </FormControl>
    )
}

export default PasswordField