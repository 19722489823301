import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useState, useEffect, useRef } from 'react';

const SelectDropDown = ({ onChange: changeValue = () => { }, value = {}, options = [], className = '', name = '', required = false, label, placeholder = '',isClearable=true,disabled=false}) => {
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef(null);
    useEffect(() => {
        // Function to handle click outside dropdown
        function handleClickOutside(event) {
            
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false);
                setSearchTerm("")
            }
        }

        // Adding event listener
        document.addEventListener("mousedown", handleClickOutside);

        // Cleanup function to remove event listener
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    function toggleDropdown() {
        setOpen(!open);
    }

    function handleInputChange(event) {
        setSearchTerm(event.target.value);
    }

    const placeholderText = !value.label ? placeholder : value.label;

    // Filtering options based on search term
    const filteredOptions = options.filter(option =>
        option.label?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div ref={dropdownRef} className={`w-full relative flex flex-col ${className}`}>
            {label && <label className={`text-xs font-semibold text-Text-Secondary mb-1 inter 2xl:mb-2 ${required && "required"}`} htmlFor={name + "id"}>{label}</label>}
            <span className='relative'>

                {!value.label ? <input
                    type="text"
                    autoFocus={false}
                    value={searchTerm}
                    disabled={disabled}
                    placeholder={placeholderText}
                    onChange={handleInputChange}
                    onClick={() => setOpen(true)}
                    className="focus:border-Primary-Color focus:border-2 border-1 border-field outline-none text-sm !rounded-lg h-10 w-full !flex items-center relative pl-3 "
                /> :

                    <button disabled={disabled} type='button' className='focus:border-Primary-Color !rounded-lg focus:border-2 border-1 border-field  h-10 w-full !flex items-center relative'>
                        <span className={`text-left dropbtn ml-3 w-full overflow-clip ${disabled && 'text-Text-Disable'}`} onClick={toggleDropdown}>{value.label}</span>
                        {value.label && isClearable && !disabled && <div className='absolute right-5 top-1/2 -translate-y-1/2'>
                            <Icon icon="system-uicons:cross" onClick={(e) => { changeValue({ target: { name, value: null } }); setSearchTerm("") }} className='cursor-pointer' />
                        </div>}
                    </button>

                }
                <Icon icon={`mingcute:${!open ? "down" : "up"}-line`} className='absolute text-field right-2 top-1/2 -translate-y-1/2 cursor-pointer' onClick={toggleDropdown} />
            </span>

            <div className={`dropdown-content w-full absolute ${open ? "!block" : "hidden"}  rounded-md z-50 p-2  max-h-44`}>
                {filteredOptions.length > 0 ? (
                    filteredOptions.map((option) => (
                        <div className='flex justify-between' key={option.value}>
                            <button
                                type='button'
                                name={name}
                                variant='text'
                                className='p-2 z-10  !rounded-lg text-left text-nowrap overflow-hidden text-ellipsis hover:!bg-menu-bg text-sm inter w-full'
                                value={option.value}
                                onClick={(e) => {
                                    changeValue({ target: { name, value: option } });
                                    toggleDropdown();
                                }}>
                                {option.label}
                            </button>

                        </div>
                    ))
                ) : (
                    <div className="text-sm text-gray-500 text-center">No options found.</div>
                )}
            </div>
        </div>
    );
};

export default SelectDropDown;
