import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ForgotSuccess from './pages/ForgotSuccess';
// import Authorization from ;
import Protected from './component/Protected';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { setCampus, setUserRole } from './store/Reducers/auth'
import Allmodulespage from './pages/Allmodulespage';
import PageNotFound from './pages/PageNotFound';
import LoaderSpinner from './helper/LoaderSpinner';
import { getRolePermissionJson } from './api/user.api';
const Authorization = lazy(() => import('./pages/Authorization'));
function App() {
  const { isAuthenticated, userRoles } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  // const [CampusAvatar, setCampusAvatar] = useState(userRoles?.avatar);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
  let [roleAccessApiCalled, setRoleAccessapicalled] = useState({})


  let campusId = localStorage.getItem("permission-campus")
  let RoleId = localStorage.getItem("permission-role")
  if (isAuthenticated && (!campusId || !RoleId)) {

    let { roles_campus = [], is_super_admin = 0 } = userRoles
    console.log(is_super_admin)
    dispatch(setCampus({
      is_super_admin,
      roles: roles_campus[0]
    }))
    localStorage.setItem("permission-campus", (roles_campus[0]?.campus_id || "Not Found"))
    localStorage.setItem("permission-role", (roles_campus[0]?.role_id || "Not Found"))
  }


  useEffect(() => {
    let callesSet
    if (campusId && RoleId && !callesSet) {

      getRolePermissionJson({
        role_id: RoleId,
        campus_id: campusId
      }).then(res => {
        setRoleAccessapicalled(res.data)
      }).catch(error => console.log(error))

    }
  }, [])
  if (!isOnline) {
    return (
      <Routes>
        <Route path="/*" element={<Navigate to="/error" />} />
        <Route path="/error" element={<PageNotFound />} />
      </Routes>
    );
  }

  return (
    <div className="bg-Background-Page">
      <Suspense fallback={<LoaderSpinner />}>
        {!isAuthenticated ? (
          <Authorization>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/forgot" element={<ForgotPassword />} />
              <Route path="/success" element={<ForgotSuccess />} />
              <Route path="/*" element={<Navigate to="/login" />} />
              <Route path="/error" element={<Navigate to="/" />} />
            </Routes>
          </Authorization>
        ) : (
          <>
            <Routes>
              <Route path="/" element={<Navigate to="/allmodule" />} />
              <Route path="/*" element={<Protected roleAccessPermis={roleAccessApiCalled} />} />
              <Route path="/allmodule" element={<Allmodulespage />} />
              <Route path="/error" element={<Navigate to="/" />} />
            </Routes>
          </>
        )}
      </Suspense>
    </div>
  );
}

export default App;
