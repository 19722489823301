import { Icon } from '@iconify/react'
import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { logout, setModule } from '../store/Reducers/auth'
import ConfirmationModal from '../helper/alertModal/ConfirmationModal'
const Allmodulespage = () => {
    let dispatch = useDispatch()
    const { userRoles } = useSelector((store) => store.auth);
    const [open, setOpen] = useState({})
    const handleClickOpen = () => {
        setOpen({
            isopen: true,
            message: <p className='text-center font-sans font-bold text-Text-Secondary'>Are you sure you want to log out? </p>,
            id: ""
        });
    };
    let handleConfirm = () => {
        dispatch(logout())
        localStorage.removeItem("permission-campus")
        localStorage.removeItem("permission-role")
        localStorage.removeItem("AuthToken")
    }


    return (
        <React.Fragment>
            <div className='h-screen'>
                <div className='text-center text-2xl inter text-Primary-Color relative pt-9 '>Campus Management Portal
                    <Button
                        type="submit"
                        variant="outlined"
                        onClick={handleClickOpen}
                        className=' !mt-4 !text-sm 2xl:text-base inter h-8 2xl:h-10 !rounded-lg !bg-Primary-Button-BG !absolute right-5 !border-none bottom-0'
                    >
                        Log out
                        <Icon icon="solar:export-line-duotone" className='text-xl ml-2 rotate-90' />
                    </Button>
                </div>
                <div className=' flex justify-center gap-4 my-4'>
                    <div>
                        <Link to='/dashboard' onClick={() => dispatch(setModule("Guest House"))} className='w-20 h-20 bg-white  inter aspect-square flex flex-col justify-around items-center shadow-md rounded-md'>
                            <Icon className='text-5xl text-Info' icon="solar:buildings-2-line-duotone" />
                        </Link>
                        <p className='text-xs text-center inter mt-2'>Guest House</p>
                    </div>
                    {userRoles?.is_super_admin==1 &&
                        <>
                            <div>
                                <Link variant="contained" to='#' className='w-20 h-20 bg-white inter aspect-square flex flex-col justify-around items-center shadow-md rounded-md'>
                                    <Icon className='text-5xl text-Secondary' icon="solar:users-group-two-rounded-line-duotone" />
                                </Link>
                                <p className='text-xs text-center inter  mt-2'>Devotee</p>

                            </div>
                            <div>
                                <Link variant="contained" to='/dashboard' onClick={() => dispatch(setModule("admin"))} className='w-20 h-20 bg-white inter aspect-square flex flex-col justify-around items-center shadow-md rounded-md'>
                                    <Icon className='text-5xl text-Info' icon="solar:settings-line-duotone" />
                                </Link>
                                <p className='text-xs text-center inter  mt-2'>Admin</p>
                            </div>
                        </>
                        }
                </div>

            </div>
            <ConfirmationModal handleDelete={handleConfirm} type='logout' submitText='Log Out' setOpen={setOpen} open={open} />
        </React.Fragment>
    )
}

export default Allmodulespage