import { request } from "./httpservice"

// Base URL for API requests, retrieved from environment variables
let base_url = process.env.REACT_APP_BASE_API_URL

/* 
* Function to get a list of countries using a GET request
* @returns {Promise} - A promise that resolves to the list of countries
*/
export const getCountry = async () => {
    return request({
        url: `${base_url}/api/get-countries`,
        method: 'GET',
    })
}

/* 
* Function to get states based on a country ID using a POST request
* @param {string} id - The ID of the country for which states are to be fetched
* @returns {Promise} - A promise that resolves to the list of states for the given country
*/
export const getStatebyCountry = async (id) => {
    return request({
        url: `${base_url}/api/get-country-wise-states`,
        method: 'POST',
        data: {"country_id":id},
    })
}

/* 
* Function to get cities based on a state ID using a POST request
* @param {string} id - The ID of the state for which cities are to be fetched
* @returns {Promise} - A promise that resolves to the list of cities for the given state
*/
export const getcitiesbyState = async (id) => {
    return request({
        url: `${base_url}/api/get-state-wise-cities`,
        method: 'POST',
        data: {"state_id":id},
    })
}
