import { Icon } from '@iconify/react/dist/iconify.js'
import React from 'react'

const LoaderSpinner = () => {
    return (
       <div className="absolute left-0  top-0  w-full h-full flex justify-center items-center bg-Background-Page">
         <div role="status">
         <Icon className='text-4xl font-bold' icon="svg-spinners:clock" />
            <span className="sr-only">Loading...</span>
        </div>
       </div>
    )
}

export default LoaderSpinner
