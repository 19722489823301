import React from 'react'
import { Button, Dialog, DialogActions, Slide } from '@mui/material';
import deleteimg from "../../assets/alertBoxIcon/bin.gif"

const Transition = React.forwardRef(function Transition(props, ref) {

  return <Slide direction="up" ref={ref} {...props} />;
});

function AlertDialogSlide({ handleDelete = () => { }, open = { isopen: false, message: '', id: null }, setOpen = () => { } }) {

  return (
    <React.Fragment>
      <Dialog
        open={open.isopen || false}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen({ isopen: false, message: '', id: null })}
        className=''
        aria-describedby="alert-dialog-slide-description"
      >
      <div className='m-3'>
      <div  className='m-2 ml-4 min-w-60' > <img src={deleteimg} className='mx-auto h-24' alt='delete.svg' /></div>
        <div className='mx-3 !font-sans text-sm'>{open.message}</div>
        <DialogActions className='m-2 flex !justify-center gap-2'>
          <Button variant='outlined'   className=' inter !rounded-lg !font-bold h-7 !p-2 !text-sm !normal-case !text-Text-Secondary  ' onClick={() => setOpen({})}>cancel</Button>
          <Button  className='!bg-Error-Color inter !rounded-lg !font-bold h-7 !p-2 !text-sm !normal-case !text-white' onClick={() => handleDelete(open.id)}>Delete</Button>
        </DialogActions>
      </div>
      </Dialog>
    </React.Fragment>
  );
}

export default AlertDialogSlide