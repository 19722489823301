import React, { memo, useEffect, useMemo } from 'react'
import Grid from '@mui/material/Grid';
import { Link, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import svpglogo from '../assets/svpglogo.png'
import { getCountry } from '../api/addres.api';
import { setCoutryList } from '../store/Reducers/modal';
import { useDispatch } from 'react-redux';

const Sidebar = ({ selectedMoule, campusSelected = {} }) => {
    let location = useLocation()
    let dispatch = useDispatch()
    useEffect(() => {
        let countryList = []
        getCountry().then((res) => {
            if (res.status === 200 && res.data) {
                res.data.map((country) => {
                    return countryList.push({
                        label: country.name,
                        value: country.id,
                        ...country
                    })
                })
                dispatch(setCoutryList(countryList))
            } else throw res
        }).catch((err) => {
            console.log("country=>err", err)
        })
    }, [dispatch])
    let { is_super_admin, roles = {} } = campusSelected

    // let updateRoute = location.pathname.includes('admin')
    // let [String(location.pathname).include(tab.path)), setTab] = useState(0)
    let tabs = useMemo(() => {
        if (selectedMoule === "admin") {
            return [
                {
                    path: 'dashboard',
                    ActiveIcon: 'solar:shield-user-bold',
                    icon: 'solar:shield-user-outline',
                    lable: "Users",
                    active: true
                },
                {
                    path: 'role',
                    ActiveIcon: 'solar:users-group-rounded-bold-duotone',
                    icon: 'solar:users-group-rounded-line-duotone',
                    lable: "Role",
                    active: true
                },
                {
                    path: 'campus',
                    ActiveIcon: 'solar:buildings-2-linear',
                    icon: 'solar:buildings-2-line-duotone',
                    lable: "Campus",
                    active: parseInt(is_super_admin)===1
                },
                {
                    path: 'profile',
                    ActiveIcon: 'solar:user-bold-duotone',
                    icon: 'solar:user-line-duotone',
                    lable: "Profile",
                    active: true
                },
                // {
                //     path: 'room',
                //     ActiveIcon: 'solar:exit-bold-duotone',
                //     icon: 'solar:exit-line-duotone',
                //     lable: "Room"
                // },
                // {
                //     path: 'report',
                //     ActiveIcon: 'solar:document-add-bold-duotone',
                //     icon: 'solar:document-add-line-duotone',
                //     lable: "Report",
                //     subtab: [
                //         {
                //             path: "createrole",
                //             label: "User"
                //         },
                //         {
                //             path: "/",
                //             label: "Daily Report"
                //         },
                //         {
                //             path: "/",
                //             label: "Other Report"
                //         },
                //         {
                //             path: "/",
                //             label: "Dettails"
                //         }
                //     ]
                // },
                // {
                //     path: '#',
                //     ActiveIcon: 'solar:user-bold-duotone',
                //     icon: 'solar:user-line-duotone',
                //     lable: "Profile",
                // },
                {
                    path: '/allmodule',
                    ActiveIcon: 'solar:widget-add-bold-duotone',
                    icon: 'solar:widget-add-line-duotone',
                    lable: "All Module",
                    active: true
                }
            ]
        }
        return [
            {
                path: 'dashboard',
                ActiveIcon: 'solar:widget-bold-duotone',
                icon: 'solar:widget-line-duotone',
                lable: "Dashboard",
                active: true
            },
            {
                path: 'guest',
                ActiveIcon: 'solar:users-group-rounded-bold-duotone',
                icon: 'solar:users-group-rounded-line-duotone',
                lable: "Guest",
                active: true
            },
            {
                path: 'room',
                ActiveIcon: 'solar:exit-bold-duotone',
                icon: 'solar:exit-line-duotone',
                lable: "Room",
                active: true
            },
            {
                path: 'profile',
                ActiveIcon: 'solar:user-bold-duotone',
                icon: 'solar:user-line-duotone',
                lable: "Profile",
                active: true
            },
            {
                path: '/allmodule',
                ActiveIcon: 'solar:widget-add-bold-duotone',
                icon: 'solar:widget-add-line-duotone',
                lable: "All Module",
                active: true
            }
        ]
    }, [selectedMoule])
    return (

        <React.Fragment>

            <Grid item xs={12} sm={12} className='flex !max-w-20  sm:!flex-row sm:items-start sm:justify-between sm:h-fit md:!flex-col md:justify-normal md:h-screen sticky top-0 bg-white z-50 border-y-0 border border-r-border'>
                <div className='w-full mx-auto flex justify-center items-center flex-col my-1'>
                    <img className='px-1' src={parseInt(is_super_admin) === 1 ? svpglogo : (roles?.campus_logo || svpglogo)} alt="svpglogo.svg" />
                    {/* <p className='inter !font-medium text-xs'>Ahmedabad</p> */}
                </div>
                <div item className='w-11/12 mx-auto '>
                    {
                        tabs.map((tab, index) => {
                            if(!tab.active) return
                            let data = String(location.pathname).split("/");
                            let istrue = tab.path.includes(data.slice(data.length - 1))
                            return <Link key={index + "i"} className={`sideBarLink aspect-auto    ${istrue ? 'bg-SidebarLink' : 'hover:bg-SidebarHover'} relative`} to={tab.path} >
                                <Icon className={`text-xl  2xl:text-3xl   ${istrue ? "text-SidebarIcon" : ""}`} icon={istrue ? tab.ActiveIcon : tab.icon} />
                                {!tab.icon.includes("solar:") && <img src={!istrue ? tab.ActiveIcon : tab.icon} alt={tab.path} className='fill-SidebarIcon' />}
                                <p className={`text-xs 2xl:text-sm    text-nowrap !font-normal ${istrue && "text-SidebarIcon"} inter`}>  {tab.lable}</p>

                                {Array.isArray(tab.subtab) && <div className={`sublinkvisible  p-1 absolute z-30 ${window.innerHeight < 300 ? 'top-8' : 'bottom-8'} w-40 bg-white shadow-lg rounded-md`}>
                                    {
                                        tab.subtab.map((label) => <Link to={label.path} role='button' key={label.label} className='p-2 z-10 rounded-md text-left block hover:!bg-menu-bg text-sm text-black inter w-full'>{label.label}</Link>)
                                    }
                                </div>}
                            </Link>
                        })
                    }
                </div>
            </Grid>

        </React.Fragment>


    )
}

export default memo(Sidebar);