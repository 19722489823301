import { ToastContainer,Zoom ,toast} from 'react-toastify';

export default function toasts (){
 return <ToastContainer
  position="top-right"
  autoClose={3000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss={false}
  draggable
  pauseOnHover
  theme="light"
  transition={Zoom}
  />
}


export const ErrorToaster=(error)=>{
  if(error.massage){
    return toast.error(error.massage)
  } else {
    return toast.error("Something went wrong!")
  }
   
}
export const WarnToaster=(error)=>{
  if(error.massage){
    return toast.warn(error.massage)
  } else {
    return toast.warn("Something went wrong!")
  }
   
}
export const SuccessToaster=(message)=>{
  if(message.massage){
    return toast.success(message.massage)
  } else {
    return toast.error("Something went wrong!")
  }
   
}