import React, { useState } from 'react';
import { Icon } from '@iconify/react/dist/iconify.js';
import { IconButton, Dialog } from '@mui/material';
import * as csvparse from 'papaparse';
import csv from '../assets/csv.svg'
const UploadedFile = ({ fileInfo = {}, handleDeleteMember, index, name, editMode = true, fileId = "" }) => {
    const [open, setOpen] = useState(false);
    const [fileUrl, setFileUrl] = useState('');
    const [imgUrl, setImgUrl] = useState('');
    const [csvData, setCsvData] = useState(null);

    const fileIcon = "https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg";

    const handleViewFile = async () => {
        try {
            let file;

            // if (typeof fileInfo === 'string' && fileInfo.endsWith('.csv')) {
            //     // Use a public CORS proxy to fetch the CSV file
            //     // const corsProxy = 'https://cors-anywhere.herokuapp.com/';
            //     const response = await fetch(fileInfo);
            //     const csvText = await response.text();
            //     const csv = csvparse.parse(csvText, { header: true });
            //     setCsvData(csv.data);
            //     setOpen(true);
            // } else if (fileInfo instanceof Blob) {
            //     file = fileInfo;
            // } else {
            file = await urlToFile(fileInfo, 'Document', 'application/octet-stream');
            // }

            if (file && file.type === 'text/csv') {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const csv = csvparse.parse(event.target.result, { header: true });
                    setCsvData(csv.data);
                    setOpen(true);
                };
                reader.readAsText(file);
            } else if (file && file.type.startsWith('image/')) {
                setImgUrl(URL.createObjectURL(file));
                setOpen(true);
            } else if (file) {
                setFileUrl(URL.createObjectURL(file));
                setOpen(true);
            }
        } catch (error) {
            console.error('Error viewing file:', error);
        }
    };

    const handleClose = () => {
        setOpen(false);
        if (fileUrl) URL.revokeObjectURL(fileUrl);
        setCsvData(null);
    };

    const urlToFile = (url, fileName, mimeType) => {
        if (url.startsWith('data:')) {
            const arr = url.split(',');
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[arr.length - 1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return Promise.resolve(new File([u8arr], fileName, { type: mime || mimeType }));
        }

        return fetch(url)
            .then(res => res.arrayBuffer())
            .then(buf => new File([buf], fileName, { type: mimeType }));
    };
    const views = () => {
        if (fileInfo.startsWith('https')) {
            if (String(fileInfo).endsWith(".csv")) return
            String(fileInfo).endsWith(".pdf") ? setFileUrl(fileInfo) : setImgUrl(fileInfo)
            setOpen(true)
        } else {
            handleViewFile()
        }
    }
    let fileType = ""
    if (typeof fileInfo === 'string') {
        if (/https?:\/\/.+\.(pdf|csv|png|jpg|svg)/.test(fileInfo)) {
            fileType = fileInfo.match(/\.([a-z]+)$/i)[1]; // Extracts file extension from URL
        }else {
            let fileCopy = fileInfo.slice(0, 40)
            if (/pdf/.test(fileCopy)) {
                fileType = 'pdf';
            } else if (/csv/.test(fileCopy)) {
                fileType = 'csv';
            } else if (/png/.test(fileCopy)) {
                fileType = 'png';
            } else if (/svg/.test(fileCopy)) {
                fileType = 'svg';
            } else if (/jpeg/.test(fileCopy)) {
                fileType = 'jpg';
            } else {
                fileType = 'unknown';
            }

        }

    }
    return (
        <div className='bg-gray w-full !py-2 my-1 border-1 border-border px-2 inter font-bold !text-sm  flex items-center justify-between rounded-md'>
            <span className='flex gap-1 inter text-xs mr-4'>

                {fileType === "csv" ? <img width={36} src={csv} /> :
                    <Icon className='text-4xl text-Primary-Color' icon={`bxs:file-${fileType}`} />}
                <div className='flex text-nowrap mt-1 items-start'>
                    <p >{`${index + 1}. ${fileType}`}</p>
                </div>
            </span>
            <span className='text-nowrap'>
                <IconButton size='small' onClick={views}>
                    <Icon className='text-sm' icon="solar:eye-line-duotone" />
                </IconButton>

                {typeof fileInfo === 'string' && fileInfo.startsWith("https") && (
                    <a target='_blank' href={fileInfo} download>
                        <IconButton size='small'>
                            <Icon className='text-sm' icon="solar:file-download-line-duotone" />
                        </IconButton>
                    </a>
                )}
                {
                    handleDeleteMember && <IconButton size='small' disabled={!editMode} onClick={(e) => handleDeleteMember(index, e, name, fileId)}>
                        <Icon className='text-sm' icon="solar:trash-bin-trash-line-duotone" />
                    </IconButton>
                }
            </span>
            <Dialog open={open} onClose={handleClose} maxWidth={imgUrl ? "md" : "lg"} fullWidth>
                {csvData ? (
                    <table className="table-auto w-full">
                        <thead>
                            <tr>
                                {Object.keys(csvData[0]).map((header) => (
                                    <th key={header} className="px-4 py-2 border">{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {csvData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {Object.values(row).map((value, colIndex) => (
                                        <td key={colIndex} className="px-4 py-2 border">{value}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : imgUrl ? (
                    <img className='mx-auto h-full object-contain' src={imgUrl} alt='Uploaded file' />
                ) : (
                    <iframe src={fileUrl} width="100%" height="600px" title="File viewer" />
                )}
            </Dialog>
        </div>
    );
};

export default UploadedFile;
