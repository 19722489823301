// this dropdown label show in side like general: "selected value"
import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useEffect, useRef, useState } from 'react'
const SelectWithLabel = ({ name,onChange:changeValue=()=>{},value={},options=[],className='',defaltlable=''}) => {
    
    let [open, setOpen] = useState(false)
    const dropdownRef = useRef(null);

    useEffect(() => {
        // Function to handle click outside dropdown
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false);
            }
        }

        // Adding event listener
        document.addEventListener("mousedown", handleClickOutside);

        // Cleanup function to remove event listener
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    function myFunction() {

        setOpen(!open)
        // ref.current.classList.toggle("show");
    }
    let classs=open?"!block":"hidden"
    return (

        <div ref={dropdownRef} className={` pl-4  transition-colors text-sm  flex relative items-center border-gray rounded-lg justify-center pr-4 ${className}`}>
            <div htmlFor="button" onClick={myFunction}  role='button' className='z-50  select-none  !flex !items-center  w-full  relative'>
                <div role='button' className=' dropbtn text-Text-Primar !font-semibold inter text-nowrap left-2' >{defaltlable}:&nbsp;</div><span className='text-left dropbtn text-Primary-Color text-sm  text-nowrap overflow-clip inline-block w-20 text-ellipsis'>{value.label}</span>
                <Icon icon={`mingcute:${!open ? "down" : "up"}-line`} className='absolute  -right-2 text-lg text-Primary-Color'/>
            </div>
            <div  className={`dropdown-content ${classs} p-2 !rounded-lg bg-white`}>
                {
                  Array.isArray(options) &&  options.map((option)=>
                  <div className='flex justify-between' key={option.value}>
                  <button
                      type='button'
                      name={name}
                      variant='text'
                      className='p-2 z-10 rounded-lg text-left text-nowrap overflow-hidden text-ellipsis hover:!bg-menu-bg text-sm text-black inter w-full'
                      value={option.value}
                      onClick={(e)=>{  changeValue({ target: { name, value: option } }); myFunction()}}>
                      {option.label}
                  </button>

              </div>)
                }
            </div>

        </div>
    )
}

export default SelectWithLabel