import React from 'react'

import { Grid } from '@mui/material'
import InputField from '../../component/CustomElement/InputField'


const PersonalDettails = ({ formData = {}, handleChange, errors= {},editMode=true }) => {

    return (
        <>
            <div className='bg-gray !py-3 border-1 border-border !px-5 inter font-bold !mb-4 !text-sm uppercase'  >Personal Details</div>
            <div className='grid gap-4 mx-5 my-4'>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={12}>
                    <Grid item md={3}>
                        <InputField
                            label={"Surname"}
                            name='surname'
                            type="text"
                            disabled={!editMode}
                            placeholder=""
                            className={errors['surname'] && "errorBoder "}
                            formData={formData.surname}
                            onChange={handleChange}
                        />
                        {
                            errors["surname"] && <p className='error'>{errors['surname']}</p>
                        }
                    </Grid>
                    <Grid item md={3}>
                        <InputField
                            label={"Name"}
                            name='name'
                            required={true}
                            type="text"
                            placeholder=""
                            className={errors['name'] && "errorBoder"}
                            disabled={!editMode}
                            formData={formData.name}
                            onChange={handleChange}
                        />
                        {
                            errors["name"] && <p className='error'>{errors['name']}</p>
                        }
                    </Grid>
                    <Grid item md={3}>
                        <InputField
                            label={"Husband/Father Name"}
                            name='father_husband_name'
                            type="text"
                            placeholder=""
                            disabled={!editMode}
                            formData={formData.father_husband_name}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <InputField
                            label='Alternative Mobile Number'
                            name='mobile_number2'
                            type="phone"
                            disabled={!editMode}
                            placeholder="+919877832437"
                            formData={formData.mobile_number2}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    )
}


export default PersonalDettails