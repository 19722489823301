export const newroomForm = {
    "id":"",  // id blank means record add.
    "division_id":"",
    "category_id":"",
    "room_name":"",
    "floor":"",
    "bed":"",
    "extra_bed":"0",
    "original_key":"0", 
    "duplicate_key":"0", 
    "extra_key":"0", 
    "remark":"",
    "air_conditioning":0, 
    "geyser":0, 
    "toilet_western":0, 
    "toilet_western_no":1,
    "toilet_indian":0,
    "toilet_indian_no":1,
    "status":"1"
}