/**
 * 
 * @param {upload files array} files  
 * @param {pass document key aadhar_card_attach:1...} document_type 
 * @param {passkey which name key you asign every file} key 
 * @returns  array of object uploaded files
 */
export const fileToDataUrlConvert = (files, document_type,key) => {
    const filePromises = Array.from(files).map(file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        if (file.type === 'image/svg+xml') {
          reader.onloadend = () => {
            const img = new Image();
            img.src = reader.result;
            
            img.onload = () => {
              const canvas = document.createElement('canvas');
              canvas.width = img.width;
              canvas.height = img.height;
              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0);
              const dataUrl = canvas.toDataURL('image/png');
              resolve({ document_type, [key]: dataUrl });
            };
            
            img.onerror = reject;
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        } else {
          reader.onloadend = () => resolve({ document_type, [key]: reader.result });
          reader.onerror = reject;
          reader.readAsDataURL(file);
        }
      });
    });
  
    return filePromises;
  }
  


