import { FormControlLabel, Grid } from '@mui/material'
import React from 'react'
import CusomCheckbox from '../CustomElement/CustomCheckbox'

const GentsLadies = ({handleChange = () => { }, formData = {}}) => {
    return (
        <Grid item md={6} lg={6} className='flex justify-between items-center gap-8'>
            <div className='flex gap-3 items-center' >
                <label className={`text-sm font-semibold text-Text-Secondary  `} htmlFor='gents'>Gents</label>
                <input
                    name='gents'
                    type='text'
                    size='small'
                    placeholder='00'
                    className='w-16 bg-Primary-Button-BG h-8 text-center  text-Primary-Color outline-none'
                    value={String(formData?.gents).replace(/[^\d+]/g, '').slice(0, 4)}
                    onChange={(e) => handleChange(e, 'office_purpose')}
                />
            </div >

            <div className='flex gap-3 items-center' >
                <label className={`text-sm font-semibold text-Text-Secondary  `} htmlFor='ladies'>Ladies</label>
                <input
                    name='ladies'
                    type='text'
                    size='small'
                    placeholder='00'
                    className='!w-16  inter bg-Primary-Button-BG  h-8 text-center   text-Primary-Color outline-none'
                    value={String(formData?.ladies).replace(/[^\d+]/g, '').slice(0, 4)}
                    onChange={(e) => handleChange(e, 'office_purpose')}
                />
            </div >
            <div className='flex gap-3 items-center' >
                <label className={`text-sm font-semibold text-Text-Secondary  `} htmlFor='child'>child</label>
                <input
                    name='child'
                    type='text'
                    size='small'
                    placeholder='00'
                    className='w-16 bg-Primary-Button-BG h-8 text-center text-Primary-Color outline-none'
                    value={String(formData?.child).replace(/[^\d+]/g, '').slice(0, 4)}
                    onChange={(e) => handleChange(e, 'office_purpose')}
                />
            </div >
           
        </Grid>
    )
}

export default GentsLadies