import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './combineRoot.store';
import { persistStore } from 'redux-persist';
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

const dateSerializerMiddleware = store => next => action => {
  if (action.type === 'modal/setRoomBookingDto') {
    const { office_purpose } = action.payload;

    if (office_purpose) {
      action.payload.office_purpose = {
        ...office_purpose,
        check_in_date: new Date(office_purpose.check_in_date).toISOString(),
        check_out_date: new Date(office_purpose.check_out_date).toISOString(),
      };
    }
  }

  return next(action);
};

// Create the Redux store with middleware
export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => 
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(dateSerializerMiddleware)
});

// Create the Redux persistor
export const persistor = persistStore(store);
