import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  roomPageTableVisible: {},
  roomPageTableColumnOrder: [],
  
  guestPageTableVisible: {},
  guestPageTableColumnOrder: [],

  userPageTableVisible: {},
  userPageTableColumnOrder: [],

  rolePageTableVisible: {},
  rolePageTableColumnOrder: [],

  campusPageTableVisible: {},
  campusPageTableColumnOrder: [],

  guestHistoryPageTableVisible: {},
  guestHistoryPageTableColumnOrder: [],

  roomHistoryPageTableVisible: {},
  roomHistoryPageTableColumnOrder: [],
};

const tablePersistConfig = {
  key: 'table',
  storage: storage,
};

export const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    setRoomPageTableVisible: (state, action) => {
      state.roomPageTableVisible = action.payload;
    },
    setRoomPageTableOrder: (state, action) => {
      state.roomPageTableColumnOrder = action.payload;
    },
    setGuestPageTableVisible: (state, action) => {
      state.guestPageTableVisible = action.payload;
    },
    setGuestPageTableOrder: (state, action) => {
      state.guestPageTableColumnOrder = action.payload;
    },
    setUserPageTableVisible: (state, action) => {
      state.userPageTableVisible = action.payload;
    },
    setUserPageTableOrder: (state, action) => {
      state.userPageTableColumnOrder = action.payload;
    },
    setRolePageTableVisible: (state, action) => {
      state.rolePageTableVisible = action.payload;
    },
    setRolePageTableOrder: (state, action) => {
      state.rolePageTableColumnOrder = action.payload;
    },
    setCampusPageTableVisible: (state, action) => {
      state.campusPageTableVisible = action.payload;
    },
    setCampusPageTableOrder: (state, action) => {
      state.campusPageTableColumnOrder = action.payload;
    },
    setGuestHistoryPageTableVisible: (state, action) => {
      state.guestHistoryPageTableVisible = action.payload;
    },
    setGuestHistoryPageTableOrder: (state, action) => {
      state.guestHistoryPageTableColumnOrder = action.payload;
    },
    setRoomHistoryPageTableVisible: (state, action) => {
      state.roomHistoryPageTableVisible = action.payload;
    },
    setRoomHistoryPageTableOrder: (state, action) => {
      state.roomHistoryPageTableColumnOrder = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { 
  setRoomPageTableVisible,
  setRoomPageTableOrder,
  setGuestPageTableVisible,
  setGuestPageTableOrder,
  setUserPageTableVisible,
  setUserPageTableOrder,
  setRolePageTableVisible,
  setRolePageTableOrder,
  setCampusPageTableVisible,
  setCampusPageTableOrder,
  setGuestHistoryPageTableVisible,
  setGuestHistoryPageTableOrder,
  setRoomHistoryPageTableVisible,
  setRoomHistoryPageTableOrder
} = tableSlice.actions;

// Reducer
export default persistReducer(tablePersistConfig, tableSlice.reducer);
