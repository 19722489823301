import React from 'react'
import { Button, Dialog, DialogActions, Slide } from '@mui/material';
import keyIssue  from "../../assets/alertBoxIcon/arrow.gif"
import keyReturn  from "../../assets/alertBoxIcon/key.gif"
import logoutlogo  from "../../assets/alertBoxIcon/logout.gif"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ConfirmationModal({ handleDelete = () => { }, open =  { isopen: false, message: '', id: null }, setOpen = () => { },submitText="Yes",cancelText="cancel",type="logout" }) {

  let iconslogo=type==="logout"?logoutlogo:type==='keyIssue'?keyIssue:type==="keyReturn" && keyReturn
  return (
    <React.Fragment>
      <Dialog
        open={open.isopen || false}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
      <div className='m-3'>
      <div  className='m-2 ml-4 min-w-60' > <img src={iconslogo} className='mx-auto h-24' alt='delete.svg' /></div>
        <div className='mx-3 !font-sans text-sm'>{open.message}</div>
        <DialogActions className='m-2 flex !justify-center gap-2'>
          <Button variant='outlined'  className=' inter !rounded-lg !font-bold h-7 !p-2 !text-sm !normal-case !text-Text-Secondary ' onClick={() => setOpen(false)}>{cancelText}</Button>
          <Button type='button'  className='hover:!bg-hover-Button-BG disabled:!bg-disabled-Button-BG !bg-Primary-Color !rounded-lg inter !font-bold h-7 !p-2 !text-sm !normal-case !text-white' onClick={() => handleDelete(true)}>{submitText}</Button>
        </DialogActions>
      </div>
      </Dialog>
    </React.Fragment>
  );
}

export default ConfirmationModal