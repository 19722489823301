import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import succes from '../assets/success.gif'
const ForgotSuccess = () => {
    let location = useLocation()
    useEffect(()=>{
       if(!location || !location.state) {
          window.location.replace('/login')
       }
    })
    return (
        <div className='h-2/3 flex flex-col px-3 ' >
            <form className='max-w-80 my-8 text-center '>
                <img width={'50px'} className='mx-auto mt-10' src={succes} alt="" />
                <h1 className='text-xl font-bold my-2 text-center'>Your Password Reset SuccessFully</h1>
                <h1 className='text-xl font-bold my-2 text-center'>{location?.state?.email.slice(0, 3) + "****" + location?.state?.email.slice(7)}</h1>
                <h1 className='text-lg font-bold my-2 text-center '>Thank You</h1>
                <Link to='/' className='flex gap-2 justify-center items-center mt-8'><button className='text-sky-500 text-sm'>Back to Login</button></Link>
            </form>
        </div>
    )
}

export default ForgotSuccess