import { Grid } from '@mui/material';
import React, { forwardRef } from 'react';
import InputField from '../../component/CustomElement/InputField';
import UploadedFile from '../UploadedFile';
import { ErrorToaster, SuccessToaster } from '../../helper/toastHeleper';
import { deleteDocumentByIdAndTableType } from '../../api/guest.api';

const DocumentDetails = ({
  formData = {},
  handleChange = () => { },
  errors = {},
  header = false,
  handleMultifile = () => { },
  editMode = true,
  
},ref) => {

  const downloadFile = (files) => {
    if (Array.isArray(files)) {
      files.forEach((file, index) => {
        setTimeout(() => {
          const link = document.createElement('a');
          link.href = file.attachment;
          link.download = 'document';
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }, index * 1000); // 1000ms delay for each file, adjust as necessary
      });
    }
  };


  const handleDeleteFile = async (index, e, name, id) => {
    const confirm = await ref.current.openModal(
      <p className='text-center font-sans font-bold text-Text-Secondary'>
        <div className='text-Error-Color'> Delete File ? </div>
        Deleting this File is permanent and<br />
        cannot be recovered. All associated data will be lost.
      </p>, "id");
    if (!confirm) return
    if (!Array.isArray(formData[name])) return
    if (id) {
      try {
        let body = { id, table_name: "guest_doc" }
        let res = await deleteDocumentByIdAndTableType(body)
        if (res.status === 200) {
          let coppiesArray = [...formData[name]]
          coppiesArray.splice(index, 1)
          handleChange({ target: { value: coppiesArray, name } })
          SuccessToaster(res)
        }
      } catch (error) {
        ErrorToaster(error)
      }
    } else if (!id) {
      let coppiesArray = [...formData[name]]
      coppiesArray.splice(index, 1)
      handleChange({ target: { value: coppiesArray, name } })
    }

  }


  return (
    <>
      {header && (
        <div className='bg-gray !py-3 border-1 border-border !px-5 inter font-bold !text-sm uppercase'>
          Document Details
        </div>
      )}
      <div className='grid gap-4 mx-5 my-2 pb-2'>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={12}>
          <Grid item md={3}>
            <div className='mb-1 2xl:mb-2'>
              <label className='!text-xs inter font-semibold text-Text-Secondary' htmlFor='aadhar_card_attach'>
                Aadhar Card
              </label>
              <div className='float-end mb-1 2xl:mb-2'>
                <label htmlFor='aadhar_card_attach' className='!text-xs cursor-pointer inter mx-1 !text-Primary-Color'>
                  + UPLOAD
                </label>
              </div>
            </div>
            <input
              id='aadhar_card_attach'
              name='aadhar_card_attach'
              onChange={(e) => handleMultifile(e, 1)}
              className='hidden'
              disabled={!editMode}
              multiple
              type='file'
              accept='image/*,.pdf'
            />
            <InputField
              name='aadhar_card_no'
              type='text'
              disabled={!editMode}
              placeholder='4736 8929 7192'
              formData={formData.aadhar_card_no}
              onChange={handleChange}
            />
            {Array.isArray(formData.aadhar_card_attach) &&
              formData.aadhar_card_attach.map((data, index) => (
                <UploadedFile fileInfo={data.attachment} handleDeleteMember={handleDeleteFile} fileId={data.id} index={index} name="aadhar_card_attach" />
              ))}
          </Grid>

          <Grid item md={3}>
            <div className='mb-1 2xl:mb-2'>
              <label className='!text-xs inter font-semibold text-Text-Secondary' htmlFor='pan_card_attach'>
                Pan Card
              </label>
              <div className='float-end mb-1 2xl:mb-2'>
                {Array.isArray(formData.pan_card_attach) && formData.pan_card_attach.length > 0 ? (
                  <>
                    {/* {editMode && (
                      <button
                        type='button'
                        onClick={() => downloadFile(formData.pan_card_attach)}
                        className='!text-xs cursor-pointer inter mx-1 !text-Primary-Color'>
                        Download
                      </button>
                    )}
                    <button
                      type='button'
                      disabled={!editMode}
                      onClick={() => { handleChange({ target: { value: [], name: "pan_card_attach", action: "delete" } }) }}
                      name='pan_card_attach'
                      className='!text-xs cursor-pointer inter mx-1 !text-Error-Color'>
                      Remove
                    </button> */}
                  </>
                ) : (
                  <label htmlFor='pan_card_attach' className='!text-xs cursor-pointer inter mx-1 !text-Primary-Color'>
                    + UPLOAD
                  </label>
                )}
              </div>
            </div>
            <input
              id='pan_card_attach'
              name='pan_card_attach'
              disabled={!editMode}
              onChange={(e) => handleMultifile(e, 2)}
              className='hidden'
              type='file'
              accept='.csv,.pdf'
            />
            <InputField
              name='pan_card_no'
              type='text'
              disabled={!editMode}
              placeholder='ABCDE1234F'
              formData={formData.pan_card_no}
              onChange={handleChange}
            />
            {Array.isArray(formData.pan_card_attach) &&
              formData.pan_card_attach.map((data, index) => (
                <UploadedFile fileInfo={data.attachment} handleDeleteMember={handleDeleteFile} fileId={data.id} index={index} name="pan_card_attach" />
              ))}
          </Grid>

          <Grid item md={3}>
            <div className='mb-1 2xl:mb-2'>
              <label className='!text-xs inter font-semibold text-Text-Secondary' htmlFor='passport_attach'>
                Passport
              </label>
              <div className='float-end mb-1 2xl:mb-2'>
                {Array.isArray(formData.passport_attach) && formData.passport_attach.length > 0 ? (
                  <>
                    {/* {editMode && (
                      <button
                        type='button'
                        onClick={() => downloadFile(formData.passport_attach)}
                        className='!text-xs cursor-pointer inter mx-1 !text-Primary-Color'>
                        Download
                      </button>
                    )}
                    <button
                      type='button'
                      disabled={!editMode}
                      onClick={() => { handleChange({ target: { value: [], name: "passport_attach", action: "delete" } }) }}
                      name='passport_attach'
                      className='!text-xs cursor-pointer inter mx-1 !text-Error-Color'>
                      Remove
                    </button> */}
                  </>
                ) : (
                  <label htmlFor='passport_attach' className='!text-xs cursor-pointer inter mx-1 !text-Primary-Color'>
                    + UPLOAD
                  </label>
                )}
              </div>
            </div>
            <input
              id='passport_attach'
              name='passport_attach'
              onChange={(e) => handleMultifile(e, 4)}
              className='hidden'
              disabled={!editMode}
              type='file'
              accept='.csv,.pdf'
            />
            <InputField
              name='passport_no'
              type='text'
              disabled={!editMode}
              placeholder='A1234567'
              formData={formData.passport_no}
              onChange={handleChange}
            />
            {Array.isArray(formData.passport_attach) &&
              formData.passport_attach.map((data, index) => (
                <UploadedFile fileInfo={data.attachment} handleDeleteMember={handleDeleteFile} fileId={data.id} index={index} name="passport_attach" />
              ))}
          </Grid>

          <Grid item md={3}>
            <div className='mb-1 2xl:mb-2'>
              <label className='!text-xs inter font-semibold text-Text-Secondary' htmlFor='driving_license_attach'>
                Driving License
              </label>
              <div className='float-end mb-1 2xl:mb-2'>
                {Array.isArray(formData.driving_license_attach) && formData.driving_license_attach.length > 0 ? (
                  <>
                    {/* {editMode && (
                      <button
                        type='button'
                        onClick={() => downloadFile(formData.driving_license_attach)}
                        className='!text-xs cursor-pointer inter mx-1 !text-Primary-Color'>
                        Download
                      </button>
                    )}
                    <button
                      type='button'
                      disabled={!editMode}
                      onClick={() => { handleChange({ target: { value: [], name: "driving_license_attach", action: "delete" } }) }}
                      name='driving_license_attach'
                      className='!text-xs cursor-pointer inter mx-1 !text-Error-Color'>
                      Remove
                    </button> */}
                  </>
                ) : (
                  <label htmlFor='driving_license_attach' className='!text-xs cursor-pointer inter mx-1 !text-Primary-Color'>
                    + UPLOAD
                  </label>
                )}
              </div>
            </div>
            <input
              id='driving_license_attach'
              name='driving_license_attach'
              onChange={(e) => handleMultifile(e, 3)}
              className='hidden'
              disabled={!editMode}
              type='file'
              accept='.csv,.pdf'
            />
            <InputField
              name='driving_license_no'
              type='text'
              disabled={!editMode}
              placeholder='GJ1234567890123'
              formData={formData.driving_license_no}
              onChange={handleChange}
            />
            {Array.isArray(formData.driving_license_attach) &&
              formData.driving_license_attach.map((data, index) => (
                <UploadedFile fileInfo={data.attachment} handleDeleteMember={handleDeleteFile} fileId={data.id} index={index} name="driving_license_attach" />
              ))}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default forwardRef(DocumentDetails);
