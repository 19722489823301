import axios from 'axios';

// Request interceptor to add authToken and X-XSRF-TOKEN
axios.interceptors.request.use((config) => {
  const xsrfToken = localStorage.getItem("AuthToken");
  const permission_campus = localStorage.getItem("permission-campus");
  const permission_role = localStorage.getItem("permission-role");
  config.headers["Authorization"] = `Bearer ${xsrfToken}`
  config.headers["permission-campus"] = permission_campus
  config.headers["permission-role"] = permission_role
  return config;
}, error => {
  // handle the request error
  return Promise.reject(error);
});

// Response interceptor for any response handling (currently empty)
axios.interceptors.response.use(response => {
  let { data: { token = "" } = {} } = response.data || {}
  if (token && !localStorage.getItem("AuthToken")) {
      localStorage.setItem("AuthToken", token);
  }
  return response;
}, error => {
  // handle the response error
  return Promise.reject(error);
});





export const request = async (body) => {
  let config = {
  }
  if (body['data']) {
    config.data = body['data']
  }
  if (body['method']) {
    config.method = body['method']
  }
  if (body['url']) {
    config.url = body['url']
  }
  if (body['header']) {
    config.header = body['header']
  }
  let data = await axios.request(config)

  return data.data
}


