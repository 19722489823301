import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { Link, useNavigate } from 'react-router-dom';
import InputField from '../component/CustomElement/InputField';
import Button from '@mui/material/Button';
import { login } from '../store/Reducers/auth';
import { userlogin } from '../api/user.api';
import { useDispatch } from 'react-redux';
import PasswordField from '../component/CustomElement/PasswordField';
import { ErrorToaster, SuccessToaster } from '../helper/toastHeleper';
import LoginLogo from '../assets/LoginLogo.png'
export default function LoginPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        email: "",
        password: ""
    });
    const [isSubmitting, setSubmit] = useState(false);
    const [Error, setError] = useState({});

    const validateData = () => {
        const requiredValue = ["email"];
        let errorCount = 0;

        requiredValue.forEach((data) => {
            if (!formData[data] || formData[data] === "") {
                setError(prev => ({ ...prev, [data]: "Field is required" }));
                errorCount++;
            } else {
                setError(prev => ({ ...prev, [data]: null }));
            }
        });
        if (formData.email && !/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(formData.email)) {
            setError(prev => ({ ...prev, email: "Enter valid Email Address" }));
            errorCount++;
        }

        return errorCount;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (formData[name]) {
            setError(prev => ({ ...prev, [name]: null }));
        }
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (validateData()) {
            return;
        }
        try {
            setSubmit(true);
            const res = await userlogin(formData);
            if (res.status === 200) {
                navigate("/allmodule");
                dispatch(login(res.data));
                SuccessToaster(res);
                setSubmit(false);
            } else {
                throw res;
            }
        } catch (error) {
            setSubmit(false);
            ErrorToaster(error);
        }
    };

    useEffect(() => {
        if (formData.password) {
            setError(prev => ({ ...prev, password: null }));
        }
    }, [formData]);

    return (
        <div className='flex flex-col px-3 h-2/3'>
            <button onClick={() => { }} className='text-sm invisible text-gray-600 -translate-y-12 flex items-center'>
                <Icon className='inline' icon="solar:alt-arrow-left-line-duotone" />
                Back
            </button>
            <div>
                <h1 className='text-xl font-bold my-2 max-w-32 text-Text-Primar'>
                    <img src={LoginLogo} alt="campus logo.png"/>
                </h1>
                <h5 className='text-sm text-Text-Secondary'> Campus Management System</h5>
            </div>
            <form className='min-w-64 my-8 2xl:min-w-80 max-w-80 2xl:max-w-80' onSubmit={handleSubmit}>
               <div className='w-full my-5'>
                    <InputField name="email" type="text" onChange={handleChange} label='Email Id' formData={formData.email} />
                    {Error.email && <p className='error absolute'>{Error.email}</p>}
                </div>
                <PasswordField name="password" type="text" onChange={setFormData} label='Password' formData={formData} />
                {Error.password && <p className='error absolute'>{Error.password}</p>}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disableElevation={true}
                    className='h-8 2xl:h-10 !rounded-lg !mt-9 !text-sm 2xl:text-base  hover:!bg-hover-Button-BG disabled:!bg-disabled-Button-BG !bg-Primary-Color'
                    disabled={isSubmitting}
                >
                    Sign In
                </Button> 
                <Link to={'/forgot'} className='flex gap-2 justify-center items-center mt-8'>
                    <Icon icon="solar:lock-keyhole-line-duotone" />
                    <span className='text-sm text-Text-Secondary'>Forgot your password?</span>
                </Link>
            </form>
        </div>
    );
}
