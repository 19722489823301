

import { request } from "./httpservice"

// Base URL for API requests, retrieved from environment variables
let base_url = process.env.REACT_APP_BASE_API_URL

/* 
* Function to get a list of countries using a GET request
* @returns {Promise} - A promise that resolves to the list of countries
*/
export const getroomList = async () => {
    return request({
        url: `${base_url}/api/list-room`,
        method: 'GET',
    })
}
export const getroomStatus = async () => {
    return request({
        url: `${base_url}/api/get-room-status-list`,
        method: 'GET',
    })
}

export const addroom = async (body) => {
    return request({
        url: `${base_url}/api/add-update-room`,
        method: 'POST',
        data:body
    })
}

export const deleteroomById = async (id) => {
    return request({
        url: `${base_url}/api/delete-room/${id}`,
        method: 'GET',
    })
}

export const getroomById = async (id) => {
    return request({
        url: `${base_url}/api/get-id-wise-room/${id}`,
        method: 'GET',
    })
}
export const getroomListAndStatus = async (id) => {
    return request({
        url: `${base_url}/api/dashboard-room-list`,
        method: 'GET',
    })
}
