


import { request } from "./httpservice"

// Base URL for API requests, retrieved from environment variables
let base_url = process.env.REACT_APP_BASE_API_URL

export const getRoomBookingStatusGuestList = async () => {
    return request({
        url: `${base_url}/api/get-room-booking-type`,
        method: 'GET',
    })
}
export const getRoomBookingStatusGuestListById = async (body) => {
    return request({
        url: `${base_url}/api/get-booking-type-wise-details`,
        method: 'POST',
        data: body,
    })
}
export const getRoomCheckinByBookId = async (body) => {
    return request({
        url: `${base_url}/api/guest-room-booked-check-in`,
        method: 'POST',
        data: body,
    })
}
export const getRoomBookingcancel= async (body) => {
    return request({
        url: `${base_url}/api/guest-room-booking-cancel`,
        method: 'POST',
        data: body,
    })
}
export const deleteAssignRoomByid= async (body) => {
    return request({
        url: `${base_url}/api/delete-assign-room`,
        method: 'POST',
        data: body,
    })
}
export const getDashboardGuestHistory= async (body) => {
    return request({
        url: `${base_url}/api/get-dashboard-guest-history`,
        method: 'POST',
        data: body,
    })
}
