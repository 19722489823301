import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import Sidebar from './Sidebar';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import Profile from '../pages/Profile';





// Lazy load your pages/components
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Room = lazy(() => import('../pages/Room'));

const Guest = lazy(() => import('../pages/Guest'));
const AddnewGuest = lazy(() => import('../pages/subpages/AddnewGuest'));

// admin module
const RoleListpage = lazy(() => import('../pages/RoleListpage'));
const AddnewRole = lazy(() => import('../pages/subpages/AddnewRole'));

const CampusListpage = lazy(() => import('../pages/CampusListpage'));
const AddNewCampuspage = lazy(() => import('../pages/subpages/AddNewCampuspage'));

const UserListpage = lazy(() => import('../pages/UserListpage'));
const AddNewUser = lazy(() => import('../pages/subpages/AddNewUser'));

const NotFoundPage = () => {
    return <div className='my-5'>Page Not Found</div>;
};

const Protected = ({ roleAccessPermis = {} }) => {
    let { Admin, Devotee, "Guest house": guestHouse = {} } = roleAccessPermis;

    let {
        Dashboard: dashboardJson,
        Guest: guestJson,
        Room: roomJson,
        // Report,
        // Notification
    } = guestHouse
    let { campusModule, campusSelected, userRoles: { is_super_admin } = {} } = useSelector(({ auth }) => auth)
    return (
        <div component="main" className='flex z-50'>
            <Sidebar selectedMoule={campusModule} campusSelected={campusSelected} />
            <div style={{ width: 'calc(100% - 80px)' }} className='overflow-y-auto pr-6 -z-0' >

                <div className='!ml-4 -z-30 '>
                    <div className='h-16'></div>

                    {campusModule === "admin" ?
                        <Routes>
                            <Route path="dashboard" element={<UserListpage />} />
                            <Route path="/dashboard/:AddNewUser" element={<AddNewUser />} />
                            {parseInt(is_super_admin) === 1 && <>
                                <Route path="/campus" element={<CampusListpage />} />
                                <Route path="/campus/:AddNewCampus" element={<AddNewCampuspage />} />
                            </>}
                            <Route path="/role" element={<RoleListpage />} />
                            <Route path="/role/:createrole" element={<AddnewRole />} />
                            <Route path="profile" element={<Profile />} />
                        </Routes> :
                        <Routes>
                            <Route path="dashboard" element={<Dashboard permissionJSON={dashboardJson} />} />
                            <Route path="/room" element={<Room permissionJSON={roomJson} />} />
                            <Route path="profile" element={<Profile />} />
                            <Route path="/guest" element={<Guest permissionJSON={guestJson} />} />
                            <Route path="/guest/:addnewguest" element={<AddnewGuest permissionJSON={guestJson} />} />
                            <Route path="*" element={<NotFoundPage />} />
                            {/* <Route path="profile" element={<>Guest House profile</>} /> */}
                        </Routes>
                    }
                </div>
            </div>


        </div>
    );
}

export default Protected;
