import { Button, FormControlLabel, Grid } from '@mui/material'
import React, { memo } from 'react'
import CusomCheckbox from '../CustomElement/CustomCheckbox'
import { Icon } from '@iconify/react/dist/iconify.js'

const RoomMaintainkey = ({ roomDettails, index, handleSelectRoomClick = () => { } }) => {
    return (
        <Grid item md={3} lg={2.4} >
            <div className='flex items-center gap-2'>
                <Button variant="contained" disabled={!(roomDettails.original_key || roomDettails.duplicate_key || roomDettails.extra_key)} onClick={() => handleSelectRoomClick(roomDettails, index)} type='button' className={`w-18 h-17 !rounded-lg ${roomDettails.status_label} inter  flex flex-col justify-around items-center `}>
                    <div className=''>
                        <p className='text-sm inter !font-bold text-center select-none'>{roomDettails.room_no}</p>
                        <p className='text-xs inter !font-bold text-center select-none'>{roomDettails.bed || 0} + {roomDettails.extra_bed || 0}</p>
                        <div className="flex justify-center mt-1 gap-2">
                            {roomDettails.air_conditioning ? <Icon icon="solar:condicioner-2-line-duotone" /> : ""}
                            {roomDettails.geyser ? <Icon icon="solar:waterdrops-outline" /> : ""}
                        </div>
                    </div>
                </Button>
                <div>

                    <span className='flex items-center'>
                        <FormControlLabel
                            id='original_key'
                            name='original_key'
                            checked={Boolean(roomDettails.original_key)}
                            className='!m-0'
                            control={<CusomCheckbox className='!p-0  !mr-2' />} />
                        <label className='text-sm font-normal text-Text-Secondary mb-1  text-nowrap ' htmlFor='sameWhattsNumber'>Original Key</label>
                    </span>
                    <span className='flex items-center'>
                        <FormControlLabel
                            id='duplicate_key'
                            name='duplicate_key'
                            checked={Boolean(roomDettails.duplicate_key)}
                            className='!m-0'
                            control={<CusomCheckbox className='!p-0  !mr-2' />} />
                        <label className='text-sm font-normal text-Text-Secondary mb-1 text-nowrap ' htmlFor='sameWhattsNumber'>Duplicate Key</label>
                    </span>
                    <span className='flex items-center'>
                        <FormControlLabel
                            id='extra_key'
                            name='extra_key'
                            checked={Boolean(roomDettails.extra_key)}
                            className='!m-0'
                            control={<CusomCheckbox className='!p-0  !mr-2' />} />
                        <label className='text-sm font-normal text-Text-Secondary mb-1  text-nowrap ' htmlFor='sameWhattsNumber'>Extra Key</label>
                    </span>

                </div>
            </div>
        </Grid>
    )
}

export default memo(RoomMaintainkey)