import { Grid } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InputField from '../../component/CustomElement/InputField';
import SelectDropDown from '../../component/CustomElement/SelectDropDown';
import { useSelector, shallowEqual } from 'react-redux';
import { getStatebyCountry, getcitiesbyState } from '../../api/addres.api.js';

const AddressDetail = ({mainLabel, formData = {}, handleChange, errors = {}, editMode = true }) => {
    const country = useSelector((store) => store.modal.country, shallowEqual);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    let fetchedCountry = {}
    const fetchStates = useCallback(async (countryId) => {
        try {
            if (fetchedCountry[countryId]) {
                setStates(fetchedCountry[countryId])
                return
            }
            const res = await getStatebyCountry(countryId);
            if (res.status === 200 && res.data) {
                const states = res.data.map((state) => ({
                    label: state.name,
                    value: state.id,
                    ...state,
                }));
                fetchedCountry[countryId] = states
                setStates(states);
            } else {
                throw res;
            }
        } catch (err) {
            console.log('country_id=>err', err);
        }
    }, []);

    let fetchedState = {}
    const fetchCities = useCallback(async (stateId) => {
        try {
            if (fetchedState[stateId]) {
                setCities(fetchedState[stateId])
                return
            }
          
            const res = await getcitiesbyState(stateId);
            if (res.status === 200 && res.data) {
                const cities = res.data.map((city) => ({
                    label: city.name,
                    value: city.id,
                    ...city,
                }));
                fetchedState[stateId] = cities
                setCities(cities);
            } else {
                throw res;
            }
        } catch (err) {
            console.log('state_id=>err', err);
        }
    }, []);

    const handleCountrySelect = useCallback(
        async (e) => {
            const { value={} } = e.target;
            handleChange(e);
            setStates([]);
            setCities([]);
            handleChange({ target: { name: 'state_id', value: '' } });
            handleChange({ target: { name: 'city_id', value: '' } });
            await fetchStates(value?.value);
        },
        [handleChange, fetchStates]
    );

    const handleStateSelect = useCallback(
        async (e) => {
            const { value={} } = e.target;
            handleChange(e);
            setCities([]);
            handleChange({ target: { name: 'city_id', value: '' } });
            await fetchCities(value?.value);
        },
        [handleChange, fetchCities]
    );

    useEffect(() => {
        if (formData.country_id) {
            fetchStates(formData.country_id);
        }
    }, [formData.country_id, fetchStates]);

    useEffect(() => {
        if (formData.state_id) {
            fetchCities(formData.state_id);
        }
    }, [formData.state_id, fetchCities]);

    const memoizedCountry = useMemo(() => country, [country]);
    const memoizedStates = useMemo(() => states, [states]);
    const memoizedCities = useMemo(() => cities, [cities]);

    return (
        <>
            <div className={`bg-gray !py-3 ${!mainLabel ? "border-1 border-border":"rounded-lg"} !px-5 inter font-bold !mb-4 !text-sm uppercase`}>
               {mainLabel? mainLabel : "Address Details"}
            </div>
            <div className='grid gap-4 mx-5 my-4'>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={12}>
                    <Grid item md={3}>
                        <InputField
                            label='House/Building/Apartment'
                            name='house_building_apartment'
                            type='text'
                            disabled={!editMode}
                            placeholder="Enter House/Building/Apartment"
                            formData={formData.house_building_apartment}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <InputField
                            label='Street/Road/Lane'
                            name='street_road_lane'
                            type='text'
                            disabled={!editMode}
                            placeholder="Near to"
                            formData={formData.street_road_lane}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <InputField
                            label='Village/Town/City'
                            name='village_town'
                            type='text'
                            required
                            disabled={!editMode}
                            placeholder='Enter Village/Town/City'
                            formData={formData.village_town}
                            onChange={handleChange}
                        />
                        {
                            errors["village_town"] && <p className='error'>{errors['village_town']}</p>
                        }
                    </Grid>
                    <Grid item md={3}>
                        <InputField
                            label='Pincode'
                            name='pincode'
                            type='text'
                            disabled={!editMode}
                            placeholder="123456"
                            formData={formData.pincode}
                            onChange={handleChange}
                        />
                    </Grid>
                   
                </Grid>
            </div>
            <div className='grid gap-4 mx-5 my-4'>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={12}>
                <Grid item md={3}>
                        <SelectDropDown
                            label='Country'
                            name='country_id'
                            disabled={!editMode}
                            options={memoizedCountry}
                            value={memoizedCountry.find((x) => x.id === formData.country_id) || {}}
                            onChange={handleCountrySelect}
                            placeholder='Select Country'
                            className=''
                        />
                    </Grid>
                    <Grid item md={3}>
                        <SelectDropDown
                            label='State'
                            name='state_id'
                            disabled={!editMode}
                            options={memoizedStates}
                            value={memoizedStates.find((x) => x.id === formData.state_id) || {}}
                            onChange={handleStateSelect}
                            placeholder='Select State'
                            className=''
                        />
                    </Grid>
                    <Grid item md={3}>
                        <SelectDropDown
                            label='District'
                            name='city_id'
                            disabled={!editMode}
                            options={memoizedCities}
                            value={memoizedCities.find((x) => x.id === formData.city_id) || {}}
                            onChange={handleChange}
                            placeholder='Select District'
                            className=''
                        />
                    </Grid>
      
                    <Grid item md={3}>
                        <InputField
                            label='Native Place'
                            name='native_place'
                            type='text'
                            disabled={!editMode}
                            placeholder='Enter Native Place'
                            formData={formData.native_place}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default AddressDetail;