import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  isAuthenticated: false,
  userRole: null,
  userRoles:null,
  authToken: "",
  campusModule:'Guest House',
  campusSelected:{},
};

const authPersistConfig = {
  key: 'root',
  storage: storage,
  // blacklist: []
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.userRoles = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.userRoles = null;
      state.userRole = null;
      state.campusSelected = {};
      state.campusModule='Guest House';
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setModule: (state, action) => {
      state.campusModule = action.payload;
    },
    setCampus: (state, action) => {
      state.campusSelected = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, logout, setUserRole,setModule,setCampus } = authSlice.actions;

// Reducer
export default persistReducer(authPersistConfig, authSlice.reducer);
