import React from 'react'

import { FormControlLabel } from '@mui/material'
import InputField from '../../component/CustomElement/InputField'
import CusomCheckbox from '../../component/CustomElement/CustomCheckbox'


const Amenties = ({formData={},handleChange=()=>{}}) => {
  return (
    <>
    <div className='bg-gray !py-3 border-1 border-border !px-5 inter font-bold  !text-sm uppercase '  >Amenities Details</div>

    <div className='flex gap-10 mx-5 my-4'>
      
        <div className='flex items-center'>
          <FormControlLabel
            name="air_conditioning"
            checked={Boolean(formData.air_conditioning)}
            onChange={handleChange}
            className="!mx-0" 
            control={<CusomCheckbox className="!pl-0" defaultChecked />} />
          <label className='text-sm font-semibold text-Text-Secondary text-nowrap' htmlFor=''>Air Conditioning</label>
        </div>

        <div  className='flex items-center  '>
          <FormControlLabel
            name="geyser"
            className="!mx-0" 
            checked={Boolean(formData.geyser)}
            onChange={handleChange}
            control={<CusomCheckbox className="!pl-0"  defaultChecked />}
          />
          <label className='text-sm font-semibold text-Text-Secondary ' htmlFor=''>Geyser</label>
        </div>
        <div className='flex justify-center items-center'>
          <FormControlLabel
            name='toilet_western'
            className="!mx-0" 
            checked={Boolean(formData.toilet_western)}
            onChange={handleChange}
            control={<CusomCheckbox className="!pl-0" defaultChecked />} />
          <label className='text-sm font-semibold text-Text-Secondary text-nowrap pr-2' htmlFor=''>Toilet-Western</label>
          <InputField
            type={'number'}
            disabled={!formData.toilet_western}
            name="toilet_western_no"
            min={1}
            className="w-14"
            formData={!formData.toilet_western?0:String(formData.toilet_western_no).slice(0,2)}
            onChange={handleChange}
          />

        </div>
        <div  className='flex justify-center items-center '>
          <FormControlLabel
            name='toilet_indian'
            checked={Boolean(formData.toilet_indian)}
            onChange={handleChange}
            className="!mx-0" 
            control={<CusomCheckbox className="!pl-0" defaultChecked />} />
          <label className='text-sm font-semibold text-Text-Secondary  text-nowrap pr-2' htmlFor=''>Toilet-Indian</label>

          <InputField
            type={'number'}
            disabled={!formData.toilet_indian}
            name="toilet_indian_no"
            formData={!formData.toilet_indian?0:String(formData.toilet_indian_no).slice(0,2)}
            className="w-14 "
            onChange={handleChange}
          />
        </div>

    </div>
  </>
  )
}

export default Amenties